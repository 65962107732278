import { useEffect, useRef } from "react";
import * as d3 from "d3";

const useD3 = (funcionRender, dependencias) => {
  const ref = useRef();
  useEffect(() => {
    funcionRender(d3.select(ref.current));
    return () => {};
  }, dependencias);
  return ref;
};

export default useD3;
