let GeneralUrls;

if (process.env.NODE_ENV === "production") {
  GeneralUrls = {
    landing_page: "https://cimacalidez.mx/",
    reservaciones_app: "https://reservaciones.cimacalidez.mx/",
    reservaciones_admin_app: "https://admin.cimacalidez.mx/",
    authentication_api: "https://auth.cimacalidez.mx/",
    reservaciones_api: "https://api.cimacalidez.mx/",
    actividad_api: "https://actapi.cimacalidez.mx/",
    administracion_api: "https://admapi.cimacalidez.mx/",
  };
} else if (process.env.NODE_ENV === "development") {
  GeneralUrls = {
    landing_page: "https://cimacalidez.mx/",
    reservaciones_app: "http://localhost:3000/",
    reservaciones_admin_app: "http://localhost:3001/",
    authentication_api: "http://localhost:8081/",
    reservaciones_api: "http://localhost:8080/",
    actividad_api: "http://localhost:8082/",
    administracion_api: "http://localhost:8083/",
  };
} else if (process.env.NODE_ENV === "test") {
  GeneralUrls = {
    landing_page: "https://cimacalidez.mx/",
    reservaciones_app: "http://localhost:3000/",
    reservaciones_admin_app: "http://localhost:3001/",
    authentication_api: "http://localhost:8081/",
    reservaciones_api: "http://localhost:8080/",
    actividad_api: "http://localhost:8082/",
    administracion_api: "http://localhost:8083/",
  };
}
export default GeneralUrls;
