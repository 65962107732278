import anime from "animejs";

const IniciarCargando = (callbackAfter = () => {}) => {
  let tiempo = 500;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: "#cargando",
      opacity: 1,
      duration: tiempo,
    },
    0
  );
};

export default IniciarCargando;
