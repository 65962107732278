import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as AuthActions from "../../redux/Actions/AuthActions";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";

//assets
import logo from "../../media/imagenes/Logotipo\ sin\ slogan\ sin\ fondo.png";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  useEffect(() => {
    if (logged) history.push("/");
  }, [logged, history]);
  return (
    <div id="login">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(AuthActions.loginUsuarioRegistrado({ email, password }));
        }}
      >
        <img src={logo} alt="logo" />
        <p>Sistema administrativo</p>
        <input
          placeholder="Email"
          type="email"
          required
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          placeholder="Contraseña"
          type="password"
          required
          onChange={(event) => setPassword(event.target.value)}
        />
        <button>Enviar</button>
      </form>
    </div>
  );
};

export default Login;
