import React from "react";
import "./ConfirmacionEnvioResumen.css";

//Redux
import { useDispatch } from "react-redux";
import * as ReservacionesActions from "../../redux/Actions/ReservacionesActions";

const ConfirmacionEnvioResumen = ({ reservacion, cerrarModal }) => {
  const dispatch = useDispatch();
  return (
    <div className="modalConfirmacionEnvioResumen">
      <p>¿Desea enviar resumen actual de la reservacion {reservacion.Codigo_de_reservacion} al cliente?</p>
      <button
        className="botonNo"
        onClick={() => {
          cerrarModal();
        }}
      >
        No
      </button>
      <button
        className="bottonSi"
        onClick={() =>
          dispatch(
            ReservacionesActions.enviarCorreoDeResumen(
              reservacion.Codigo_de_reservacion
            )
          )
        }
      >
        Si
      </button>
    </div>
  );
};

export default ConfirmacionEnvioResumen;
