import React, { useEffect, useState, Fragment } from "react";
import "./Usuarios.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as UsuariosActions from "../../redux/Actions/UsuariosActions";
import * as UsuariosSelector from "../../redux/Selectors/UsuariosSelector";

//Componentes
import TablaDinamica from "../../Componentes/TablaDinamica/TablaDinamica";
import EditorDeUsuario from "../../Componentes/EditorDeUsuario/EditorDeUsuario";
import VisualizadorDeUsuario from "../../Componentes/VisualizadorDeUsuario/VisualizadorDeUsuario";
import ConfirmacionDeBorrado from "../../Componentes/ConfirmacionDeBorrado/ConfirmacionDeBorrado";
import Modal from "../../Componentes/Modal/Modal";

//Utils
import fechaLarga from "../../utils/fechaLarga";
import calcularEdad from "../../utils/calcularEdad";

//Icons
import * as BootstrapIcons from "react-icons/bs";
import * as MaterialDesignIcons from "react-icons/md";

const Usuarios = () => {
  const dispatch = useDispatch();
  const todosLosUsuarios = useSelector((state) =>
    UsuariosSelector.todosLosUsuarios(state)
  );
  const refrescarUsuarios = useSelector((state) =>
    UsuariosSelector.refrescarUsuarios(state)
  );
  const [modalEditar, setModalEditar] = useState(false);
  const [modalVer, setModalVer] = useState(false);
  const [modalBorrar, setModalBorrar] = useState(false);
  const [usuarioABorrar, setUsuarioABorrar] = useState(null);
  useEffect(() => {
    dispatch(UsuariosActions.getUsuariosTodos());
  }, []);
  useEffect(() => {
    if (refrescarUsuarios) {
      dispatch(UsuariosActions.getUsuariosTodos());
      setModalBorrar(false);
      setModalEditar(false);
      setModalVer(false);
    }
  }, [refrescarUsuarios]);
  const usuariosParser = (array) => {
    return array.map((datos) => {
      return {
        Id: datos._id,
        nombre: datos.nombre,
        apellido: datos.apellido,
        email: datos.email,
        edad: calcularEdad(new Date(datos.fechaNacimiento)),
        fecha_de_registro: fechaLarga(new Date(datos.fechaRegistro)),
        verificado: datos.verificado ? "Si" : "No",
        Borrado: datos.borradoLogico ? "Si" : "No",
        Rol: datos.rol,
      };
    });
  };
  const constructorDeObjectoAcciones = () => {
    if (todosLosUsuarios) {
      let obj = {};
      todosLosUsuarios.forEach((usuario) => {
        obj[usuario._id] = (
          <Fragment>
            {!usuario.borradoLogico && (
              <button
                className="botones-columna-acciones-usuarios"
                onClick={() => {
                  dispatch(UsuariosActions.getInfoUsuario(usuario._id));
                  setModalEditar(true);
                }}
              >
                <BootstrapIcons.BsPencilSquare />
              </button>
            )}
            {!usuario.borradoLogico && (
              <button
                className="botones-columna-acciones-usuarios"
                onClick={() => {
                  dispatch(UsuariosActions.getInfoUsuario(usuario._id));
                  setModalVer(true);
                }}
              >
                <MaterialDesignIcons.MdVisibility />
              </button>
            )}
            <button
              className="botones-columna-acciones-usuarios"
              onClick={() => {
                setUsuarioABorrar(usuario);
                setModalBorrar(true);
              }}
            >
              <BootstrapIcons.BsTrashFill />
            </button>
          </Fragment>
        );
      });
      return obj;
    }
  };
  return (
    <div className="router panel-usuarios">
      <TablaDinamica
        datos={todosLosUsuarios && usuariosParser(todosLosUsuarios)}
        tieneBuscador={true}
        acciones={constructorDeObjectoAcciones()}
      />
      <Modal visibilidad={modalEditar} setModal={setModalEditar}>
        <EditorDeUsuario cerrarModal={() => setModalEditar(false)} />
      </Modal>
      <Modal visibilidad={modalVer} setModal={setModalVer}>
        <VisualizadorDeUsuario cerrarModal={() => setModalVer(false)} />
      </Modal>
      <Modal visibilidad={modalBorrar} setModal={setModalBorrar}>
        {usuarioABorrar && (
          <ConfirmacionDeBorrado
            cerrarModal={() => {
              setModalBorrar(false);
              setUsuarioABorrar(null);
            }}
            mensaje={`Desea eliminar la cuenta de ${usuarioABorrar.nombre} ${usuarioABorrar.apellido}`}
            funcionDeConfirmacion={() =>
              dispatch(
                UsuariosActions.eliminarUsuario({ userId: usuarioABorrar._id })
              )
            }
          />
        )}
      </Modal>
    </div>
  );
};

export default Usuarios;
