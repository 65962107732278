import * as types from "../actionTypes";

const initialState = {
  reservaciones: {
    directas: [],
    airbnb: [],
    archivosAdjuntos: [],
  },
  fechasBloqueadas: null,
  tarifas: null,
  minimoNochesExcepciones: null,
  costo: null,
  costoTotal: null,
  refrescarReservaciones: false,
  cargandoBloqueoODesbloqueo: false,
  cargandoReservaciones: false,
};

export default function ReservacionesReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_RESERVACIONES:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.GET_RESERVACIONES_SUCCESS:
      return {
        ...state,
        reservaciones: {
          directas: action.resultados.data.directas.reverse(),
          airbnb: action.resultados.data.airbnb,
          archivosAdjuntos: action.resultados.data.archivosAdjuntos,
        },
        fechasBloqueadas: action.resultados.data.bloqueadas,
        tarifas: action.resultados.data.tarifas,
        minimoNochesExcepciones: action.resultados.data.minimoNochesExcepciones,
        refrescarReservaciones: false,
        cargandoReservaciones: false,
      };
    case types.GET_RESERVACIONES_ERROR:
      return {
        ...state,
        reservaciones: { directas: [], airbnb: [], archivosAdjuntos: [] },
        cargandoReservaciones: false,
      };
    case types.GET_COSTO:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.GET_COSTO_SUCCESS:
      return {
        ...state,
        costo: action.resultados.data,
        costoTotal: action.resultados.data.costoTotal,
        cargandoReservaciones: false,
      };
    case types.GET_COSTO_ERROR:
      return {
        ...state,
        costo: null,
        costoTotal: null,
        cargandoReservaciones: false,
      };
    case types.RESET_COSTO:
      return {
        ...state,
        costo: null,
        costoTotal: null,
      };
    case types.PUT_RESERVACION:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.PUT_RESERVACION_SUCCESS:
      return {
        ...state,
        costo: null,
        costoTotal: null,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.PUT_RESERVACION_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.CAMBIAR_ESTATUS_RESERVACION:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.CAMBIAR_ESTATUS_RESERVACION_SUCCESS:
      return {
        ...state,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.CAMBIAR_ESTATUS_RESERVACION_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.CANCELAR_RESERVACION:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.CANCELAR_RESERVACION_SUCCESS:
      return {
        ...state,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.CANCELAR_RESERVACION_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_SUCCESS:
      return {
        ...state,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.BORRAR_RESERVACION:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.BORRAR_RESERVACION_SUCCESS:
      return {
        ...state,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.BORRAR_RESERVACION_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.ENVIAR_CORREO_DE_RESUMEN:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.ENVIAR_CORREO_DE_RESUMEN_SUCCESS:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.ENVIAR_CORREO_DE_RESUMEN_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    case types.BLOQUEAR_FECHAS:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.BLOQUEAR_FECHAS_SUCCESS:
      return {
        ...state,
        cargandoBloqueoODesbloqueo: true,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.BLOQUEAR_FECHAS_ERROR:
      return {
        ...state,
        cargandoBloqueoODesbloqueo: true,
        cargandoReservaciones: false,
      };
    case types.DESBLOQUEAR_FECHAS:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.DESBLOQUEAR_FECHAS_SUCCESS:
      return {
        ...state,
        cargandoBloqueoODesbloqueo: true,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.DESBLOQUEAR_FECHAS_ERROR:
      return {
        ...state,
        cargandoBloqueoODesbloqueo: true,
        cargandoReservaciones: false,
      };
    case types.CAMBIAR_MINIMO_DE_NOCHES:
      return {
        ...state,
        cargandoReservaciones: true,
      };
    case types.CAMBIAR_MINIMO_DE_NOCHES_SUCCESS:
      return {
        ...state,
        refrescarReservaciones: true,
        cargandoReservaciones: false,
      };
    case types.CAMBIAR_MINIMO_DE_NOCHES_ERROR:
      return {
        ...state,
        cargandoReservaciones: false,
      };
    default:
      return { ...state };
  }
}
