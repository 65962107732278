import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ligarEmailCupon } from "../../redux/Actions/CuponesActions";
import "./LigarEmailACupon.css";

const LigarEmailACupon = ({ funcionCancelar, cupon }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  return (
    <form id="formLigarEmailACupon">
      <p>Ingrese una dirección de email para ligar el cupón {cupon.Id}</p>
      <p>se enviará un correo electrónico automáticamente</p>
      <input required type="email" onChange={(e) => setEmail(e.target.value)} />
      <div className="divBotonesLigarEmailACupon"><button
        className="botonCancelar"
        onClick={(e) => {
          e.preventDefault();
          funcionCancelar();
        }}
      >
        Cancelar
      </button>
      <button
        className="botonLigar"
        onClick={(e) => {
          e.preventDefault();
          if (document.getElementById("formLigarEmailACupon").checkValidity()) {
            dispatch(ligarEmailCupon({ IdCupon: cupon.Id, email: email }));
          } else {
            document.getElementById("formLigarEmailACupon").reportValidity()();
          }
        }}
      >
        Ligar
      </button></div>
      
    </form>
  );
};

export default LigarEmailACupon;
