import React, { useState, useEffect } from "react";
import "./EditorDeUsuario.css";

//Redux
import { useSelector, useDispatch } from "react-redux";
import * as UsuariosSelector from "../../redux/Selectors/UsuariosSelector";
import * as UsuariosActions from "../../redux/Actions/UsuariosActions";

//utils
import jsToSQLDate from "../../utils/NodeToSQLDateParser";

const EditorDeUsuario = ({ cerrarModal }) => {
  const dispatch = useDispatch();
  const usuarioEnfocado = useSelector((state) =>
    UsuariosSelector.usuarioEnfocado(state)
  );
  const [nombre, setNombre] = useState(null);
  const [apellido, setApellido] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [email, setEmail] = useState(null);
  const [fechaNacimiento, setFechaNacimiento] = useState(null);
  const [rol, setRol] = useState(null);
  useEffect(() => {
    if (usuarioEnfocado) {
      setNombre(usuarioEnfocado.nombre);
      setApellido(usuarioEnfocado.apellido);
      setTelefono(usuarioEnfocado.telefono);
      setEmail(usuarioEnfocado.email);
      setFechaNacimiento(usuarioEnfocado.fechaNacimiento);
      setRol(usuarioEnfocado.rol);
    }
  }, [usuarioEnfocado]);
  return (
    <div id="editorDeUsuario">
      {usuarioEnfocado && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              UsuariosActions.actualizarUsuario({
                userId: usuarioEnfocado._id,
                nombre,
                apellido,
                telefono,
                email,
                fechaNacimiento,
                rol,
              })
            );
          }}
        >
          <label>Nombre</label>
          <input
            type="text"
            placeholder={usuarioEnfocado.nombre}
            onChange={(event) => setNombre(event.target.value)}
          />
          <label>Apellido</label>
          <input
            type="text"
            placeholder={usuarioEnfocado.apellido}
            onChange={(event) => setApellido(event.target.value)}
          />
          <label>Teléfono</label>
          <input
            type="text"
            placeholder={usuarioEnfocado.telefono}
            onChange={(event) => setTelefono(event.target.value)}
          />
          <label>email</label>
          <input
            type="email"
            placeholder={usuarioEnfocado.email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <label>Fecha de naciemiento</label>
          <input
            type="date"
            defaultValue={usuarioEnfocado.fechaNacimiento
              .split("T")[0]}
            onChange={(event) => setFechaNacimiento(event.target.value)}
          />
          <label>Rol</label>
          <select
            defaultValue={usuarioEnfocado.rol}
            onChange={(event) => setRol(event.target.value)}
          >
            <option>Cliente</option>
            <option>Cliente distinguido</option>
            <option>Cliente especial</option>
            <option>Agencia de viajes</option>
            <option>Operador</option>
            <option>Coordinador</option>
          </select>
          <div className="divBotones">
            <button
              onClick={(e) => {
                e.preventDefault();
                cerrarModal();
              }}
            >
              Cancelar
            </button>
            <button type="submit">Actualizar</button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditorDeUsuario;
