import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../ApiCalls";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getReservacionesSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `reservaciones/todas/`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    console.log("Reservaciones solicitadas exitosamente.");
    yield put({ type: types.GET_RESERVACIONES_SUCCESS, resultados });
  } catch (error) {
    Swal.fire(
      "Hubo un error!",
      "No fue posible recibir la información de todas las reservaciones",
      "error"
    );
    console.log("Error al solicitar todas las reservaciones: ", error);
    yield put({ type: types.GET_RESERVACIONES_ERROR, payload });
  }
}

export function* getCostoSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `costos?fechaDeEntrada=${payload.fechaDeEntrada}&fechaDeSalida=${payload.fechaDeSalida}&numeroDeAdultos=${payload.numeroDeAdultos}&numeroDeMascotas=${payload.numeroDeMascotas}&cabana=${payload.cabana}`,
      null,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.GET_COSTO_SUCCESS, resultados });
  } catch (error) {
    console.log("Error al solicitar costos: ", error);
    Swal.fire(
      "Hubo un error!",
      "No fue posible determinar el costo, vuelva a intentar nuevamente",
      "error"
    );
    yield put({ type: types.GET_COSTO_ERROR, payload });
  }
}

export function* putReservacionesSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `reservaciones/actualizar_por_admin/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    Swal.fire("Se actualizó la reservación", "", "success");
    console.log("Reservacion actualizada exitosamente.");
    yield put({ type: types.PUT_RESERVACION_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    console.log("Error al actualizar la reservación: ", error);
    yield put({ type: types.PUT_RESERVACION_ERROR, payload });
  }
}

export function* cambiarEstatusSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `reservaciones/estatus/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    Swal.fire("Se actualizó el estatus", "", "success");
    yield put({ type: types.CAMBIAR_ESTATUS_RESERVACION_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.CAMBIAR_ESTATUS_RESERVACION_ERROR, payload });
  }
}

export function* cancelarReservacionSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `reservaciones/estatus/`,
      { codigoDeReservacion: payload, estatus: "Cancelada" },
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    console.log("Reservacion cancelada exitosamente.");
    yield put({ type: types.CANCELAR_RESERVACION_SUCCESS, resultados });
  } catch (error) {
    Swal.fire(
      "Hubo un error!",
      "No fue posible cancelar la reservación",
      "error"
    );
    console.log("Error al cancelar la reservación: ", error);
    yield put({ type: types.CANCELAR_RESERVACION_ERROR, payload });
  }
}

export function* cancelarReservacionYOrdenDePagoSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `mercado_pago/cancelar_orden_de_pago/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    Swal.fire("Reservacion cancelada junto con orden de pago", "", "success");
    yield put({
      type: types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({
      type: types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_ERROR,
      payload,
    });
  }
}

export function* borrarReservacionSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "DELETE",
      `reservaciones/eliminar/`,
      { codigoDeReservacion: payload },
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    console.log("Reservacion eliminada exitosamente.");
    yield put({ type: types.BORRAR_RESERVACION_SUCCESS, resultados });
  } catch (error) {
    Swal.fire(
      "Hubo un error!",
      "No fue posible eliminar la reservación",
      "error"
    );
    console.log("Error al eliminar la reservación: ", error);
    yield put({ type: types.BORRAR_RESERVACION_ERROR, payload });
  }
}

export function* enviarCorreoDeResumenSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `reservaciones/enviar_resumen_reservacion/`,
      { codigoDeReservacion: payload },
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    Swal.fire("Correo enviado!", "", "success");
    yield put({ type: types.ENVIAR_CORREO_DE_RESUMEN_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.ENVIAR_CORREO_DE_RESUMEN_ERROR, payload });
  }
}

export function* bloquearFechasSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `disponibilidad/bloqueos/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.BLOQUEAR_FECHAS_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", "No fue posible bloquear las fechas", "error");
    console.log("Error al bloquear fechas: ", error);
    yield put({ type: types.BLOQUEAR_FECHAS_ERROR, payload });
  }
}

export function* desbloquearFechasSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `disponibilidad/desbloqueos/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.DESBLOQUEAR_FECHAS_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire(
      "Hubo un error!",
      "No fue posible desbloquear las fechas",
      "error"
    );
    console.log("Error al desbloquear fechas: ", error);
    yield put({ type: types.DESBLOQUEAR_FECHAS_ERROR, payload });
  }
}

export function* cambiarMinimoDeNochesSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `disponibilidad/minimo_de_noches_excepciones/`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.CAMBIAR_MINIMO_DE_NOCHES_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.CAMBIAR_MINIMO_DE_NOCHES_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_RESERVACIONES, getReservacionesSaga);
  yield takeLatest(types.GET_COSTO, getCostoSaga);
  yield takeLatest(types.PUT_RESERVACION, putReservacionesSaga);
  yield takeLatest(types.CAMBIAR_ESTATUS_RESERVACION, cambiarEstatusSaga);
  yield takeLatest(types.CANCELAR_RESERVACION, cancelarReservacionSaga);
  yield takeLatest(
    types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO,
    cancelarReservacionYOrdenDePagoSaga
  );
  yield takeLatest(types.BORRAR_RESERVACION, borrarReservacionSaga);
  yield takeLatest(types.ENVIAR_CORREO_DE_RESUMEN, enviarCorreoDeResumenSaga);
  yield takeLatest(types.BLOQUEAR_FECHAS, bloquearFechasSaga);
  yield takeLatest(types.DESBLOQUEAR_FECHAS, desbloquearFechasSaga);
  yield takeLatest(types.CAMBIAR_MINIMO_DE_NOCHES, cambiarMinimoDeNochesSaga);
}
