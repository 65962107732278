import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as AuthActions from "../redux/Actions/AuthActions";
import * as AuthSelectors from "../redux/Selectors/AuthSelector";

//Services
import { getToken } from "../services/tokenServices";

const ReduxListener = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logged = useSelector((state) => AuthSelectors.logged(state));
  let location = useLocation();
  let path = location.pathname;
  useEffect(() => {
    if (getToken()) {
      dispatch(AuthActions.verificaAccessToken());
    } else if (!logged && !getToken()) {
      history.push("/login");
    } else {
      dispatch(AuthActions.logOut());
    }
  }, [getToken(), path, logged]);
  return <></>;
};

export default ReduxListener;
