import React, { useState, useEffect } from "react";
import "./TablaDinamica.css";

//icons
import * as BootstrapIcons from "react-icons/bs";

/**
 * @param {{
 * datos: Array,
 * puedeCrear: Boolean,
 * puedeActualizar: Boolean,
 * puedeBorrar: Boolean,
 * textoBotonCrear: String,
 * textoBotonActualizar: String,
 * textoBotonBorrar: String,
 * funcionCrear: Function,
 * funcionActualizar: Function,
 * funcionBorrar: Function,
 * tieneCheckbox: Boolean,
 * tieneBuscador: Boolean,
 * mostrarColumnaID: Boolean,
 * acciones: Object,
 * botonesAdicionales: Array,
 * filaSeleccionable: Boolean,
 * funcionSeleccionDeFila: Function,
 * }}
 */

const TablaDinamica = ({
  datos,
  puedeCrear,
  puedeActualizar,
  puedeBorrar,
  textoBotonCrear,
  textoBotonActualizar,
  textoBotonBorrar,
  funcionCrear,
  funcionActualizar,
  funcionBorrar,
  tieneCheckbox,
  tieneBuscador,
  mostrarColumnaID,
  acciones,
  botonesAdicionales,
  filaSeleccionable,
  funcionSeleccionDeFila,
}) => {
  const [idsCheckedBoxes, setIdsCheckedBoxes] = useState([]);
  const [ordenamiento, setOrdenamiento] = useState(null);
  const [ascendente, setAscendente] = useState(true);
  const [busqueda, setBusqueda] = useState(null);
  const [campoDeBusqueda, setCampoDeBusqueda] = useState(null);
  useEffect(() => {
    setAscendente(true);
  }, [ordenamiento]);
  const constructorHeader = () => {
    if (datos[0])
      return Object.keys(datos[0])
        .filter((elemento) => {
          if (!mostrarColumnaID) {
            return elemento === "Id" ? false : true;
          } else {
            return true;
          }
        })
        .map((elemento, index) => (
          <th
            key={index}
            onClick={() => {
              if (ordenamiento === elemento) setAscendente(!ascendente);
              setOrdenamiento(elemento);
            }}
          >
            {elemento
              .split("")
              .map((elemento) => elemento.replace("_", " "))
              .join("")
              .toUpperCase()}
            {elemento === ordenamiento ? (
              ascendente ? (
                <BootstrapIcons.BsFillCaretDownFill />
              ) : (
                <BootstrapIcons.BsFillCaretUpFill />
              )
            ) : (
              ""
            )}
          </th>
        ));
  };
  const constructorBody = () => {
    if (datos)
      return (
        datos
          //Funcion de filtrado
          .filter((dato) => {
            if (busqueda && campoDeBusqueda && dato[campoDeBusqueda]) {
              let indexInicial = dato[campoDeBusqueda]
                .toLowerCase()
                .split("")
                .indexOf(busqueda[0].toLowerCase());
              let indexFinal = indexInicial + busqueda.length;

              if (
                dato[campoDeBusqueda]
                  .toLowerCase()
                  .split("")
                  .slice(indexInicial, indexFinal)
                  .join("") === busqueda.toLowerCase()
              ) {
                return true;
              } else {
                return false;
              }
            } else if (busqueda && campoDeBusqueda && !dato[campoDeBusqueda]) {
              return false;
            } else {
              return true;
            }
          })
          //Funcion de ordenamiento
          .sort((a, b) => {
            if (
              (a[ordenamiento] ? a[ordenamiento] : "0") <
              (b[ordenamiento] ? b[ordenamiento] : "0")
            ) {
              return ascendente ? -1 : 1;
            }
            if (
              (a[ordenamiento] ? a[ordenamiento] : "0") >
              (b[ordenamiento] ? b[ordenamiento] : "0")
            ) {
              return ascendente ? 1 : -1;
            }
            return 0;
          })
          .map((dato, index1) => (
            <tr
              key={index1}
              className={filaSeleccionable ? "seleccionable" : ""}
              onClick={
                funcionSeleccionDeFila
                  ? () => funcionSeleccionDeFila(dato)
                  : () => {}
              }
            >
              {tieneCheckbox && (
                <td className="columna-check-tabla-dinamica">
                  <input
                    type="checkbox"
                    onChange={(event) => {
                      if (
                        event.target.checked === false &&
                        idsCheckedBoxes.includes(dato.Id)
                      ) {
                        let array = [...idsCheckedBoxes];
                        array.splice(array.indexOf(dato.Id), 1);
                        setIdsCheckedBoxes(array);
                      } else if (
                        event.target.checked === true &&
                        !idsCheckedBoxes.includes(dato.Id)
                      ) {
                        let array = [...idsCheckedBoxes];
                        array.push(dato.Id);
                        setIdsCheckedBoxes(array);
                      }
                    }}
                  />
                </td>
              )}
              {acciones && (
                <td className="columna-acciones-tabla-dinamica">
                  {acciones[dato.Id]}
                </td>
              )}
              {Object.keys(dato)
                .filter((elemento) => {
                  if (!mostrarColumnaID) {
                    return elemento === "Id" ? false : true;
                  } else {
                    return true;
                  }
                })
                .map((key, index2) => (
                  <td key={index2}>{dato[key]}</td>
                ))}
            </tr>
          ))
      );
  };
  return (
    <div className="contenedor-general-tabla-dinamica">
      <div className="barra-acciones-tabla-dinamica">
        {botonesAdicionales}
        {puedeCrear && (
          <button
            className="tabla-dinamica-botones-crud"
            onClick={() => funcionCrear()}
          >
            <BootstrapIcons.BsPlusCircleFill /> {textoBotonCrear}
          </button>
        )}{" "}
        {puedeActualizar && (
          <button
            className="tabla-dinamica-botones-crud"
            onClick={() => funcionActualizar()}
          >
            <BootstrapIcons.BsPencil />
            {textoBotonActualizar}
          </button>
        )}
        {puedeBorrar && (
          <button
            className="tabla-dinamica-botones-crud"
            onClick={() => funcionBorrar()}
          >
            <BootstrapIcons.BsTrashFill />
            {textoBotonBorrar}
          </button>
        )}
        {tieneBuscador && (
          <div className="buscador-tabla-dinamica">
            <BootstrapIcons.BsSearch />
            <input
              type="text"
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <select
              defaultValue=""
              onChange={(event) => setCampoDeBusqueda(event.target.value)}
            >
              <option value="" disabled hidden>
                Seleccione un campo
              </option>
              {datos.length > 0 &&
                Object.keys(datos[0])
                  .filter((elemento) => {
                    if (!mostrarColumnaID) {
                      return elemento === "Id" ? false : true;
                    } else {
                      return true;
                    }
                  })
                  .map((elemento, index) => (
                    <option key={index}>
                      {elemento
                        .split("")
                        .map((elemento) => elemento.replace("_", " "))
                        .join("")}
                    </option>
                  ))}
            </select>
          </div>
        )}
      </div>
      <div className="div-tabla-dinamica">
        <table className="tabla-dinamica">
          <thead>
            <tr>
              {tieneCheckbox && (
                <th className="columna-check-tabla-dinamica">
                  <BootstrapIcons.BsFillCheckSquareFill />
                </th>
              )}
              {acciones && <th>ACCIONES</th>}
              {constructorHeader()}
            </tr>
          </thead>
          <tbody>{constructorBody()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaDinamica;
