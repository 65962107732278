import * as types from "../actionTypes";

const initialState = {
  cupones: [],
  cargandoCupones: false,
  refrescar: false,
  colecciones: [],
};

export default function CuponesReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUPONES_TODOS:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.GET_CUPONES_TODOS_SUCCESS:
      return {
        ...state,
        cupones: action.resultados.data,
        cargandoCupones: false,
        refrescar: false,
      };
    case types.GET_CUPONES_TODOS_ERROR:
      return {
        ...state,
        cupones: [],
        cargandoCupones: false,
      };
    case types.POST_CUPON:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.POST_CUPON_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.POST_CUPON_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.PUT_CUPON:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.PUT_CUPON_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.PUT_CUPON_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.ACTIVAR_DESACTIVAR_CUPON:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.ACTIVAR_DESACTIVAR_CUPON_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.ACTIVAR_DESACTIVAR_CUPON_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.LIGAR_EMAIL_CUPON:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.LIGAR_EMAIL_CUPON_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.LIGAR_EMAIL_CUPON_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.DELETE_CUPON:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.DELETE_CUPON_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.DELETE_CUPON_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.GET_TODAS_COLECCIONES_CUPONES:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.GET_TODAS_COLECCIONES_CUPONES_SUCCESS:
      return {
        ...state,
        colecciones: action.resultados.data,
        cargandoCupones: false,
        refrescar: false,
      };
    case types.GET_TODAS_COLECCIONES_CUPONES_ERROR:
      return {
        ...state,
        colecciones: [],
        cargandoCupones: false,
      };
    case types.POST_COLECCION_CUPONES:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.POST_COLECCION_CUPONES_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.POST_COLECCION_CUPONES_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.PUT_COLECCION_CUPONES:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.PUT_COLECCION_CUPONES_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.PUT_COLECCION_CUPONES_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.DELETE_COLECCION_CUPONES:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.DELETE_COLECCION_CUPONES_SUCCESS:
      return {
        ...state,
        cargandoCupones: false,
        refrescar: true,
      };
    case types.DELETE_COLECCION_CUPONES_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    case types.GET_CUPONES_Y_COLECCIONES:
      return {
        ...state,
        cargandoCupones: true,
      };
    case types.GET_CUPONES_Y_COLECCIONES_SUCCESS:
      return {
        ...state,
        cupones: action.cupones.data,
        colecciones: action.colecciones.data,
        cargandoCupones: false,
        refrescar: false,
      };
    case types.GET_CUPONES_Y_COLECCIONES_ERROR:
      return {
        ...state,
        cargandoCupones: false,
      };
    default:
      return { ...state };
  }
}
