function nombreDeCabana(string) {
    if (string) {
      let arr = string.toString().split("-");
      return arr
        .map((cabana) => {
          let nombre;
          switch (Number(cabana)) {
            case 1:
              nombre = "Ave azul";
              break;
            case 2:
              nombre = "Jilguero";
              break;
            case 3:
              nombre = "Tigrillo";
              break;
            case 4:
              nombre = "Colibrí";
              break;
            case 5:
              nombre = "Coa";
              break;
            default:
              break;
          }
          return nombre;
        })
        .join(" - ");
    }
  }

export default nombreDeCabana;