import { put, call, takeLatest } from "redux-saga/effects";
import { authCall } from "../ApiCalls";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* loginUsuarioRegistradoSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "POST",
      `api/admin/login`,
      payload,
      {
        "Content-Type": "application/json",
      }
    );
    yield put({ type: types.LOGIN_USUARIO_REGISTRADO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.LOGIN_USUARIO_REGISTRADO_ERROR, payload });
  }
}

export function* verificaAccessTokenSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "GET",
      `api/auth/autorizacion_para_panel_administrador`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken()
      }
    );
    yield put({ type: types.VERIFICA_ACCESS_TOKEN_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.VERIFICA_ACCESS_TOKEN_ERROR, payload });
  }
}

export function* putImagenDePerfilSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "PUT",
      "api/users/imagenPerfil",
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.PUT_IMAGEN_PERFIL_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.PUT_IMAGEN_PERFIL_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.LOGIN_USUARIO_REGISTRADO, loginUsuarioRegistradoSaga);
  yield takeLatest(types.VERIFICA_ACCESS_TOKEN, verificaAccessTokenSaga);
  yield takeLatest(types.PUT_IMAGEN_PERFIL, putImagenDePerfilSaga);
}
