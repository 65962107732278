import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as CuponesActions from "../../redux/Actions/CuponesActions";
import * as CuponesSelectors from "../../redux/Selectors/CuponesSelector";
import * as BootstrapIcons from "react-icons/bs";
import "./Cupones.css";

//Componentes
import TablaDinamica from "../../Componentes/TablaDinamica/TablaDinamica";
import Modal from "../../Componentes/Modal/Modal";
import EditorDeCupon from "../../Componentes/EditorDeCupon/EditorDeCupon";
import ConfirmacionDeBorrado from "../../Componentes/ConfirmacionDeBorrado/ConfirmacionDeBorrado";
import LigarEmailACupon from "../../Componentes/LigarEmailACupon/LigarEmailACupon";
import NuevaColeccionCupones from "../../Componentes/NuevaColeccionCupones/NuevaColeccionCupones";

//utils
import binarioABoleano from "../../utils/binarioABoleano";

const Cupones = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cupones = useSelector((state) => CuponesSelectors.cupones(state));
  const colecciones = useSelector((state) =>
    CuponesSelectors.colecciones(state)
  );
  const refrescar = useSelector((state) => CuponesSelectors.refrescar(state));
  const [verModalNuevoCupon, setVerModalNuevoCupon] = useState(false);
  const [verModalActualizarCupon, setVerModalActualizarCupon] = useState(false);
  const [verModalActivarDesactivar, setVerModalActivarDesactivar] =
    useState(false);
  const [verModalBorrarCupon, setVerModalBorrarCupon] = useState(false);
  const [verModalLigarEmailACupon, setVerModalLigarEmailACupon] =
    useState(false);
  const [datosDeCupon, setDatosDeCupon] = useState(null);
  const [verModalNuevaColeccion, setVerModalNuevaColeccion] = useState(false);
  const [tab, setTab] = useState("Cupones");
  useEffect(() => {
    dispatch(CuponesActions.getCuponesYColecciones());
  }, [dispatch]);
  useEffect(() => {
    if (refrescar) {
      dispatch(CuponesActions.getCuponesYColecciones());
      setVerModalNuevoCupon(false);
      setVerModalNuevaColeccion(false);
      setVerModalActualizarCupon(false);
      setVerModalActivarDesactivar(false);
      setVerModalBorrarCupon(false);
      setVerModalLigarEmailACupon(false);
    }
  }, [refrescar, dispatch]);
  const cuponParser = ({
    Id,
    Tipo,
    Valor,
    Inicio,
    Fin,
    Creacion,
    Expiracion,
    Activo,
    email,
    Codigo_de_reservacion,
    Coleccion,
  }) => {
    return {
      Id,
      Tipo,
      Valor,
      Inicio,
      Fin,
      Creación: Creacion,
      Expiración: Expiracion,
      Activo: binarioABoleano(Activo) ? "Si" : "No",
      email,
      "Código de reservación": Codigo_de_reservacion,
      Colección: Coleccion ? Coleccion : "Sin asociar",
    };
  };
  const coleccionParser = ({ _id, nombre, descripcion }) => {
    return {
      Id: _id,
      Nombre: nombre,
      Descripción: descripcion,
    };
  };
  const constructorDeObjectoAccionesCupon = () => {
    if (cupones) {
      let obj = {};
      cupones.forEach((cupon) => {
        obj[cupon.Id] = (
          <Fragment>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalActualizarCupon(true);
              }}
            >
              <BootstrapIcons.BsPencilSquare />
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalBorrarCupon(true);
              }}
            >
              <BootstrapIcons.BsTrashFill />
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalActivarDesactivar(true);
              }}
            >
              {binarioABoleano(cupon.Activo) ? (
                <BootstrapIcons.BsFillXCircleFill />
              ) : (
                <BootstrapIcons.BsCheckCircleFill />
              )}
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalLigarEmailACupon(true);
              }}
            >
              <BootstrapIcons.BsFillEnvelopeFill />
            </button>
          </Fragment>
        );
      });
      return obj;
    }
  };
  return (
    <div className="router panel-cupones">
      <div className="div-tabs-cupones">
        <p
          className={
            tab === "Cupones" ? "tab-cupones-activo" : "tab-cupones-inactivo"
          }
          onClick={() => {
            setTab("Cupones");
          }}
        >
          Cupones
        </p>
        <p
          className={
            tab === "Colecciones"
              ? "tab-cupones-activo"
              : "tab-cupones-inactivo"
          }
          onClick={() => {
            setTab("Colecciones");
          }}
        >
          Colecciones
        </p>
      </div>
      <div className="div-tabla-dinamica-cupones">
        {tab === "Cupones" && (
          <TablaDinamica
            datos={cupones && cupones.map((cupon) => cuponParser(cupon))}
            mostrarColumnaID={true}
            puedeCrear={true}
            tieneBuscador={true}
            funcionCrear={() => setVerModalNuevoCupon(true)}
            acciones={constructorDeObjectoAccionesCupon()}
          />
        )}
        {tab === "Colecciones" && (
          <TablaDinamica
            datos={
              colecciones && colecciones.map((cupon) => coleccionParser(cupon))
            }
            puedeCrear={true}
            tieneBuscador={true}
            funcionCrear={() => setVerModalNuevaColeccion(true)}
            filaSeleccionable={true}
            funcionSeleccionDeFila={(dato) =>
              history.push(`cupones/coleccion/${dato.Id}`)
            }
          />
        )}
      </div>
      <Modal visibilidad={verModalNuevoCupon} setModal={setVerModalNuevoCupon}>
        <EditorDeCupon
          crearOActualizar={"crear"}
          accionCancelar={() => setVerModalNuevoCupon(false)}
        />
      </Modal>
      <Modal
        visibilidad={verModalNuevaColeccion}
        setModal={setVerModalNuevaColeccion}
      >
        <NuevaColeccionCupones
          funcionCancelar={() => setVerModalNuevaColeccion(false)}
        />
      </Modal>
      <Modal
        visibilidad={verModalActualizarCupon}
        setModal={setVerModalActualizarCupon}
      >
        <EditorDeCupon
          crearOActualizar={"actualizar"}
          accionCancelar={() => setVerModalActualizarCupon(false)}
          datosDeCupon={datosDeCupon}
        />
      </Modal>
      <Modal
        visibilidad={verModalBorrarCupon}
        setModal={setVerModalBorrarCupon}
      >
        <ConfirmacionDeBorrado
          cerrarModal={() => setVerModalBorrarCupon(false)}
          mensaje={datosDeCupon && `¿Desea borrar el cupón ${datosDeCupon.Id}?`}
          funcionDeConfirmacion={() => {
            dispatch(CuponesActions.deleteCupon({ IdCupon: datosDeCupon.Id }));
          }}
        />
      </Modal>
      <Modal
        visibilidad={verModalActivarDesactivar}
        setModal={setVerModalActivarDesactivar}
      >
        <ConfirmacionDeBorrado
          cerrarModal={() => setVerModalActivarDesactivar(false)}
          mensaje={
            datosDeCupon &&
            `¿Desea ${
              binarioABoleano(datosDeCupon.Activo) ? "desactivar" : "activar"
            } el cupón ${datosDeCupon.Id}?`
          }
          funcionDeConfirmacion={() => {
            dispatch(
              CuponesActions.activarDesactivarCupon({
                IdCupon: datosDeCupon.Id,
              })
            );
          }}
        />
      </Modal>
      <Modal
        visibilidad={verModalLigarEmailACupon}
        setModal={setVerModalLigarEmailACupon}
      >
        <LigarEmailACupon
          funcionCancelar={() => setVerModalLigarEmailACupon(false)}
          cupon={datosDeCupon}
        />
      </Modal>
    </div>
  );
};

export default Cupones;
