import React from "react";
import "./ConfirmacionDeCancelacion.css";

//Redux
import { useDispatch } from "react-redux";
import * as ReservacionesActions from "../../redux/Actions/ReservacionesActions";

const ConfirmacionDeCancelacion = ({ reservacion, cerrarModal }) => {
  const dispatch = useDispatch();
  return (
    <div className="modalConfirmacionDeCancelacion">
      <p>Desea cancelar la reservacion {reservacion.Codigo_de_reservacion}</p>
      <button
        className="botonNo"
        onClick={() => {
          cerrarModal();
        }}
      >
        No
      </button>
      <button
        className="bottonSi"
        onClick={() =>
          dispatch(
            ReservacionesActions.cancelarReservacion(
              reservacion.Codigo_de_reservacion
            )
          )
        }
      >
        Si
      </button>
    </div>
  );
};

export default ConfirmacionDeCancelacion;
