import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

//Redux
import * as AuthSelectors from "../redux/Selectors/AuthSelector";

//Service
import { getToken } from "../services/tokenServices";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const logged = useSelector((state) => AuthSelectors.logged(state));
  return (
    <Route
      {...rest}
      render={(props) =>
        logged || getToken() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
