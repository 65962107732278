import React, { useEffect, Fragment } from "react";
import "./VisualizadorDeUsuario.css";

//Redux
import { useSelector } from "react-redux";
import * as UsuariosSelector from "../../redux/Selectors/UsuariosSelector";

//utils
import calcularEdad from "../../utils/calcularEdad";
import fechaLarga from "../../utils/fechaLarga";

const VisualizadorDeUsuario = ({ cerrarModal }) => {
  const usuarioEnfocado = useSelector((state) =>
    UsuariosSelector.usuarioEnfocado(state)
  );

  return (
    <div id="visualizadorDeUsuario">
      {usuarioEnfocado && (
        <Fragment>
          {usuarioEnfocado.imagenPerfil && (
            <img src={usuarioEnfocado.imagenPerfil} alt="Imagen de perfil" />
          )}
          <h3>{usuarioEnfocado.nombre}</h3>
          <h3>{usuarioEnfocado.apellido}</h3>
          <p>{calcularEdad(new Date(usuarioEnfocado.fechaNacimiento))} años</p>
          <p>{usuarioEnfocado.rol}</p>
          <p>
            Miembro desde el{" "}
            {fechaLarga(new Date(usuarioEnfocado.fechaRegistro))}
          </p>
          <p>{usuarioEnfocado.verificado ? "Verificado" : "Sin verificar"}</p>
          {usuarioEnfocado.telefono && (
            <a href={`tel:${usuarioEnfocado.telefono}`}>
              {usuarioEnfocado.telefono}
            </a>
          )}
          <a href={`mailto:${usuarioEnfocado.email}`}>
            {usuarioEnfocado.email}
          </a>
          <button onClick={() => cerrarModal()}>Cerrar</button>
        </Fragment>
      )}
    </div>
  );
};

export default VisualizadorDeUsuario;
