import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FaPercent, FaMoneyBillWave } from "react-icons/fa";
import "./EditorDeCupon.css";

//Redux
import * as CuponesActions from "../../redux/Actions/CuponesActions";
import * as CuponesSelector from "../../redux/Selectors/CuponesSelector";

//utils
import nodeToSQLDateParser from "../../utils/NodeToSQLDateParser";

const EditorDeCupon = ({ crearOActualizar, accionCancelar, datosDeCupon }) => {
  const dispatch = useDispatch();
  const { idColeccion } = useParams();
  const [tipo, setTipo] = useState(datosDeCupon ? datosDeCupon.Tipo : false);
  const [valor, setValor] = useState(datosDeCupon ? datosDeCupon.Valor : 0);
  const [inicio, setInicio] = useState(
    datosDeCupon ? datosDeCupon.Inicio : undefined
  );
  const [fin, setFin] = useState(datosDeCupon ? datosDeCupon.Fin : undefined);
  const [expiracion, setExpiracion] = useState(
    datosDeCupon ? datosDeCupon.Expiracion : undefined
  );
  const [coleccion, setColeccion] = useState(undefined);
  const colecciones = useSelector((state) =>
    CuponesSelector.colecciones(state)
  );
  useEffect(() => {
    dispatch(CuponesActions.getTodasColeccionesCupones());
  }, [dispatch]);
  useEffect(() => {
    if (idColeccion && colecciones) {
      let col = colecciones.find((col) => col._id === idColeccion);
      if (col) setColeccion(col.nombre);
    }
  }, [idColeccion, colecciones]);

  useEffect(() => {
    if (tipo === "porcentaje" && valor > 100) {
      setValor(100);
    }
  }, [tipo, valor]);

  return (
    <div className="nuevo-cupon">
      <h2>
        {crearOActualizar === "crear"
          ? "Nuevo"
          : crearOActualizar === "actualizar"
          ? "Modificar"
          : ""}{" "}
        Cupón
      </h2>
      <div className="coleccion-div">
        <h3>Colección</h3>
        <select
          onChange={(e) => setColeccion(e.target.value)}
          value={coleccion}
        >
          <option value={undefined}>Sin colección</option>
          {colecciones &&
            colecciones.map((col, index) => (
              <option key={index} value={col.nombre}>
                {col.nombre}
              </option>
            ))}
        </select>
      </div>
      <div className="selector-tipo-div">
        <h3>Tipo</h3>
        <div
          onClick={() => setTipo("porcentaje")}
          className={
            tipo === "porcentaje"
              ? "boton-porcentaje seleccionado"
              : "boton-porcentaje no-seleccionado"
          }
        >
          <FaPercent />
          <p>Porcentaje</p>
        </div>
        <div
          onClick={() => setTipo("dinero")}
          className={
            tipo === "dinero"
              ? "boton-dinero seleccionado"
              : "boton-dinero no-seleccionado"
          }
        >
          <FaMoneyBillWave />
          <p>Dinero</p>
        </div>
      </div>
      <div className="valor-div">
        <h3>Valor</h3>
        <input
          disabled={!tipo}
          type="number"
          min={1}
          max={tipo === "porcentaje" ? 100 : ""}
          value={valor}
          onChange={(e) => setValor(e.target.value)}
        />
      </div>
      <div className="fechas-div">
        <h3>Fechas</h3>
        <div className="inicio">
          <label>Inicio</label>
          <input
            type="date"
            value={inicio}
            min={nodeToSQLDateParser(Date.now())}
            onChange={(e) => {
              setInicio(e.target.value);
              setFin(null);
              setExpiracion(null);
            }}
          />
        </div>
        <div className="fin">
          <label>Fin</label>
          <input
            disabled={!inicio}
            value={fin}
            type="date"
            min={inicio}
            onChange={(e) => setFin(e.target.value)}
          />
        </div>
        <div className="expiracion">
          <label>Expiración</label>
          <input
            disabled={!inicio}
            value={expiracion}
            type="date"
            min={nodeToSQLDateParser(Date.now())}
            max={fin}
            onChange={(e) => setExpiracion(e.target.value)}
          />
        </div>
      </div>
      <div className="botones-div">
        <button className="boton-cancelar" onClick={() => accionCancelar()}>
          Cancelar
        </button>
        <button
          disabled={!tipo || valor <= 0 || !inicio || !fin || !expiracion}
          className="boton-accion"
          onClick={() => {
            if (tipo && valor > 0 && inicio && fin && expiracion) {
              if (crearOActualizar === "crear") {
                dispatch(
                  CuponesActions.postCupon({
                    tipo,
                    valor,
                    inicio,
                    fin,
                    expiracion,
                    coleccion:
                      coleccion === "Sin colección" ? undefined : coleccion,
                  })
                );
              } else if (crearOActualizar === "actualizar") {
                dispatch(
                  CuponesActions.putCupon({
                    IdCupon: datosDeCupon.Id,
                    tipo,
                    valor,
                    inicio,
                    fin,
                    expiracion,
                    coleccion:
                      coleccion === "Sin colección" ? undefined : coleccion,
                  })
                );
              }
            }
          }}
        >
          {crearOActualizar.charAt(0).toUpperCase() + crearOActualizar.slice(1)}
        </button>
      </div>
    </div>
  );
};

export default EditorDeCupon;
