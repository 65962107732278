import { put, call, takeLatest } from "redux-saga/effects";
import { admApiCall } from "../ApiCalls";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getCuentasFinanzasSaga({ payload }) {
  try {
    const resultados = yield call(admApiCall, "GET", `finanzas/cuentas`, null, {
      "Content-Type": "application/json",
      "x-auth-token": getToken(),
    });
    yield put({ type: types.GET_CUENTAS_FINANZAS_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_CUENTAS_FINANZAS_ERROR, payload });
  }
}

export function* getMovimientosMensualesSaga({ payload }) {
  try {
    const resultados = yield call(
      admApiCall,
      "GET",
      `finanzas/movimientos_mensuales?mes=${payload.mes}&anio=${payload.anio}&idCuenta=${payload.idCuenta}`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.GET_MOVIMIENTOS_MENSUALES_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_MOVIMIENTOS_MENSUALES_ERROR, payload });
  }
}

export function* postIngresoSaga({ payload }) {
  try {
    const resultados = yield call(
      admApiCall,
      "POST",
      `finanzas/ingreso`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.POST_INGRESO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.POST_INGRESO_ERROR, payload });
  }
}

export function* postEgresoSaga({ payload }) {
  try {
    const resultados = yield call(
      admApiCall,
      "POST",
      `finanzas/egreso`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.POST_EGRESO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.POST_EGRESO_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_CUENTAS_FINANZAS, getCuentasFinanzasSaga);
  yield takeLatest(
    types.GET_MOVIMIENTOS_MENSUALES,
    getMovimientosMensualesSaga
  );
  yield takeLatest(types.POST_INGRESO, postIngresoSaga);
  yield takeLatest(types.POST_EGRESO, postEgresoSaga);
}
