import { get } from "lodash";

export const todosLosUsuarios = (state) =>
  get(state, "UsuariosReducer.todosLosUsuarios");
export const usuarioEnfocado = (state) =>
  get(state, "UsuariosReducer.usuarioEnfocado");
export const cargandoUsuarios = (state) =>
  get(state, "UsuariosReducer.cargandoUsuarios");
  export const refrescarUsuarios = (state) =>
  get(state, "UsuariosReducer.refrescarUsuarios");
