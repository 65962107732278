import { put, call, takeLatest } from "redux-saga/effects";
import { authCall } from "../ApiCalls";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getUsuariosTodosSaga({ payload }) {
  try {
    const resultados = yield call(authCall, "GET", `api/admin/todos`, payload, {
      "Content-Type": "application/json",
      "x-auth-token": getToken(),
    });
    yield put({ type: types.GET_USUARIOS_TODOS_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_USUARIOS_TODOS_ERROR, payload });
  }
}

export function* getInfoUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "GET",
      `api/admin/userInfo?userId=${payload}`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.GET_INFO_USUARIO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_INFO_USUARIO_ERROR, payload });
  }
}

export function* registrarUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "POST",
      `api/admin/signup`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.REGISTRAR_USUARIO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.REGISTRAR_USUARIO_ERROR, payload });
  }
}

export function* actualizarUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "PUT",
      `api/admin/actualizar_usuario`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.ACTUALIZAR_USUARIO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.ACTUALIZAR_USUARIO_ERROR, payload });
  }
}

export function* eliminarUsuarioSaga({ payload }) {
  try {
    const resultados = yield call(
      authCall,
      "DELETE",
      `api/admin/eliminar_usuario`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.ELIMINAR_USUARIO_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.ELIMINAR_USUARIO_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_USUARIOS_TODOS, getUsuariosTodosSaga);
  yield takeLatest(types.GET_INFO_USUARIO, getInfoUsuarioSaga);
  yield takeLatest(types.REGISTRAR_USUARIO, registrarUsuarioSaga);
  yield takeLatest(types.ACTUALIZAR_USUARIO, actualizarUsuarioSaga);
  yield takeLatest(types.ELIMINAR_USUARIO, eliminarUsuarioSaga);
}
