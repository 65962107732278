import anime from "animejs";

const CargandoLogoOutline = () => {
  anime({
    targets: '#LogoContorno path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 1000,
    delay: function(el, i) { return i * 250 },
    direction: "alternate",
    loop: true
  });
};

export default CargandoLogoOutline;
