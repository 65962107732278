import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./DesgloseMovimientos.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as FinanzasActions from "../../../../redux/Actions/FinanzasActions";
import * as FinanzasSelectors from "../../../../redux/Selectors/FinanzasSelector";

//Componentes
import TablaDinamica from "../../../../Componentes/TablaDinamica/TablaDinamica";
import BotonNuevoEgreso from "../../../../Componentes/BotonNuevoEgreso/BotonNuevoEgreso";
import BotonNuevoIngreso from "../../../../Componentes/BotonNuevoIngreso/BotonNuevoIngreso";

//Utils
import fechaCorta from "../../../../utils/fechaMesYDiaCorto";
import mesString from "../../../../utils/mesString";

//Icons
import * as BoxIcons from "react-icons/bi";

const DesgloseMovimientos = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const movimientos = useSelector((state) =>
    FinanzasSelectors.movimientos(state)
  );
  const refrescar = useSelector((state) => FinanzasSelectors.refrescar(state));
  const { idCuenta } = useParams();
  const [mes, setMes] = useState(new Date(Date.now()).getMonth());
  const [anio, setAnio] = useState(new Date(Date.now()).getFullYear());
  useEffect(() => {
    dispatch(FinanzasActions.getMovimientosMensuales({ mes, anio, idCuenta }));
  }, [mes, anio, dispatch, idCuenta]);
  useEffect(() => {
    if (refrescar)
      dispatch(
        FinanzasActions.getMovimientosMensuales({ mes, anio, idCuenta })
      );
  }, [mes, anio, dispatch, idCuenta, refrescar]);
  const ingresosParser = (datos) => {
    return {
      Id: datos._id,
      fecha: fechaCorta(new Date(datos.fecha)),
      cantidad: "$" + datos.cantidad,
      descripcion: datos.descripcion,
      fuente: datos.fuente,
      usuario: datos.usuario.nombre,
    };
  };
  const egresosParser = (datos) => {
    return {
      Id: datos._id,
      fecha: fechaCorta(new Date(datos.fecha)),
      cantidad: "$" + datos.cantidad,
      descripcion: datos.descripcion,
      categoria: datos.categoria,
      facturado: datos.facturado ? "Si" : "No",
      usuario: datos.usuario.nombre,
    };
  };
  return (
    <div className="router panel-finanzas-desglose-movimientos">
      <div className="regresar" onClick={() => history.push("/finanzas")}>
        <BoxIcons.BiChevronsLeft />
        <p>Cuentas</p>
      </div>

      {movimientos && movimientos.cuenta && (
        <div className="cuenta-movimientos">
          <p className="nombre-balance">
            {movimientos.cuenta.nombre} ${movimientos.cuenta.balance}
          </p>
          <div className="fecha">
            <BoxIcons.BiChevronLeftCircle
              onClick={() => {
                if (mes <= 0) {
                  setMes(11);
                  setAnio(anio - 1);
                } else {
                  setMes(mes - 1);
                }
              }}
            />
            <div className="mes-anio">
              <select
                defaultValue={Number(new Date(Date.now()).getMonth())}
                onChange={(event) => setMes(event.target.value)}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                  (mesNumerico, index) => (
                    <option
                      key={index}
                      label={mesString(mesNumerico)}
                      value={Number(mesNumerico)}
                    >
                      {mesNumerico}
                    </option>
                  )
                )}
              </select>
              <input
                type="number"
                value={anio}
                onChange={(event) => setAnio(event.target.value)}
              />
            </div>

            <BoxIcons.BiChevronRightCircle
              onClick={() => {
                if (mes >= 11) {
                  setMes(0);
                  setAnio(anio + 1);
                } else {
                  setMes(mes + 1);
                }
              }}
            />
          </div>
          <div className="ingresos">
            <p>Ingresos</p>
            <TablaDinamica
              datos={movimientos.ingresos.map((ingreso) =>
                ingresosParser(ingreso)
              )}
              tieneBuscador={true}
            />
          </div>
          <div className="egresos">
            <p>Egresos</p>
            <TablaDinamica
              datos={movimientos.egresos.map((egreso) => egresosParser(egreso))}
              tieneBuscador={true}
            />
          </div>
        </div>
      )}
      <div className="botones-ingresos-egresos">
        <BotonNuevoIngreso cuentaPredeterminada={idCuenta} />
        <BotonNuevoEgreso cuentaPredeterminada={idCuenta} />
      </div>
    </div>
  );
};

export default DesgloseMovimientos;
