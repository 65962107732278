import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as ReservacionesActions from "../../redux/Actions/ReservacionesActions";
import * as ReservacionesSelectors from "../../redux/Selectors/ReservacionesSelector";
import * as BootstrapIcons from "react-icons/bs";
import * as MaterialDesignIcons from "react-icons/md";
import "./Reservaciones.css";

//Componentes
import TablaDinamica from "../../Componentes/TablaDinamica/TablaDinamica";
import Modal from "../../Componentes/Modal/Modal";
import VisualizadorDeReservacion from "../../Componentes/VisualizadorDeReservacion/VisualizadorDeReservacion";
import EditorDeReservacion from "../../Componentes/EditorDeReservacion/EditorDeReservacion";
import ConfirmacionDeCancelacion from "../../Componentes/ConfirmacionDeCancelacion/ConfirmacionDeCancelacion";
import ConfirmacionDeBorrado from "../../Componentes/ConfirmacionDeBorrado/ConfirmacionDeBorrado";
import ConfirmacionEnvioResumen from "../../Componentes/ConfirmacionEnvioResumen/ConfirmacionEnvioResumen";
import ModalReservaciones from "./ModalReservaciones/ModalReservaciones";

//Utils
import fechaLargaConHorasMinutos from "../../utils/fechaLargaConHorasMinutos";

const Reservaciones = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reservaciones = useSelector((state) =>
    ReservacionesSelectors.reservaciones(state)
  );
  const refrescarReservaciones = useSelector((state) =>
    ReservacionesSelectors.refrescarReservaciones(state)
  );
  const { codigoDeReservacion } = useParams();
  const [origen, setOrigen] = useState("Directas");
  const [animacion, setAnimacion] = useState("");
  const [reservacionEnfocada, setReservacionEnfocada] = useState(null);
  const [modalCrear, setModalCrear] = useState(false);
  const [modalVer, setModalVer] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalBorrar, setModalBorrar] = useState(false);
  const [modalEnvioResumen, setModalEnvioResumen] = useState(false);
  const reservacionDirectaParser = ({
    Codigo_de_reservacion,
    Estatus,
    Fecha_de_confirmacion,
    Cliente,
    Telefono,
    email,
    Cabana,
    Fecha_de_entrada,
    Fecha_de_salida,
    Adultos,
    Ninos,
    Bebes,
    Mascotas,
    Costo_total,
    Anticipo,
    Pago_pendiente,
    Metodo_de_pago,
  }) => {
    return {
      Id: Codigo_de_reservacion,
      "Codigo de Reservacion": Codigo_de_reservacion,
      Estatus,
      "Fecha de confirmacion": fechaLargaConHorasMinutos(
        new Date(Fecha_de_confirmacion)
      ),
      Cliente,
      Telefono,
      email,
      Cabaña: Cabana,
      "Fecha de entrada": Fecha_de_entrada,
      "Fecha de salida": Fecha_de_salida,
      Adultos,
      Niños: Ninos,
      Bebes,
      Mascotas,
      "Costo total": Costo_total,
      Anticipo: Anticipo ? Anticipo : 0,
      "Pago pendiente": Pago_pendiente ? Pago_pendiente : Costo_total,
      "Metodo de pago": Metodo_de_pago,
    };
  };
  const reservacionAirbnbParser = ({
    Codigo_de_reservacion,
    Cabana,
    Fecha_de_entrada,
    Fecha_de_salida,
    URL,
  }) => {
    return {
      "Codigo de Reservacion": Codigo_de_reservacion,
      Cabaña: Cabana,
      "Fecha de entrada": Fecha_de_entrada,
      "Fecha de salida": Fecha_de_salida,
      URL: (
        <a href={URL} target="_blank" rel="noreferrer">
          {URL}
        </a>
      ),
    };
  };
  const activaAnimacion = (tab) => {
    if (!animacion) {
      setAnimacion("animacion-cambio-tabla-reservaciones");
      setTimeout(() => setOrigen(tab), 500);
    }
  };
  const constructorDeObjectoAcciones = () => {
    if (reservaciones) {
      let obj = {};
      reservaciones.directas.forEach((reservacion) => {
        obj[reservacion.Codigo_de_reservacion] = (
          <Fragment>
            <button
              className="botones-columna-acciones-reservaciones"
              onClick={() => {
                setReservacionEnfocada(reservacion);
                setModalEditar(true);
              }}
            >
              <BootstrapIcons.BsPencilSquare />
            </button>
            <button
              className="botones-columna-acciones-reservaciones"
              onClick={() => {
                setReservacionEnfocada(reservacion);
                setModalVer(true);
              }}
            >
              <MaterialDesignIcons.MdVisibility />
            </button>
            {reservacion.Estatus !== "Cancelada" && (
              <button
                className="botones-columna-acciones-reservaciones"
                onClick={() => {
                  setReservacionEnfocada(reservacion);
                  setModalCancelar(true);
                }}
              >
                <MaterialDesignIcons.MdBlock />
              </button>
            )}
            <button
              className="botones-columna-acciones-reservaciones"
              onClick={() => {
                setReservacionEnfocada(reservacion);
                setModalBorrar(true);
              }}
            >
              <BootstrapIcons.BsTrashFill />
            </button>
            <button
              className="botones-columna-acciones-reservaciones"
              onClick={() => {
                setReservacionEnfocada(reservacion);
                setModalEnvioResumen(true);
              }}
            >
              <BootstrapIcons.BsFillEnvelopeFill />
            </button>
          </Fragment>
        );
      });
      return obj;
    }
  };
  useEffect(() => {
    dispatch(ReservacionesActions.getReservaciones());
  }, [dispatch]);
  useEffect(() => {
    if (animacion) {
      setTimeout(() => setAnimacion(""), 1000);
    }
  }, [animacion]);
  useEffect(() => {
    if (refrescarReservaciones) {
      dispatch(ReservacionesActions.getReservaciones());
      setModalEditar(false);
      setModalCancelar(false);
      setModalBorrar(false);
    }
  }, [refrescarReservaciones, dispatch]);
  useEffect(() => {
    if (codigoDeReservacion) {
      reservaciones.directas.forEach((reservacion) => {
        if (reservacion.Codigo_de_reservacion === codigoDeReservacion) {
          setReservacionEnfocada(reservacion);
          setModalVer(true);
          history.push("/reservaciones");
        }
      });
    }
  }, [codigoDeReservacion, reservaciones, history]);
  return (
    <div className="router panel-reservaciones">
      <div className="div-titulo-navegacion-reservaciones">
        <p className="titulo-pagina">Reservaciones</p>
        <div className="navegacion-reservaciones">
          <p
            className={origen === "Directas" ? "activa" : "inactiva"}
            onClick={() => activaAnimacion("Directas")}
          >
            Directas
          </p>
          <p
            className={origen === "Airbnb" ? "activa" : "inactiva"}
            onClick={() => activaAnimacion("Airbnb")}
          >
            Airbnb
          </p>
        </div>
      </div>
      <div className={"div-tabla-reservaciones".concat(" ").concat(animacion)}>
        {origen === "Directas" && (
          <TablaDinamica
            datos={
              reservaciones &&
              reservaciones.directas &&
              reservaciones.directas.map((reservacion) =>
                reservacionDirectaParser(reservacion)
              )
            }
            funcionCrear={() => setModalCrear(true)}
            puedeBorrar={false}
            textoBotonCrear={"Crear Reservación"}
            tieneBuscador={true}
            acciones={constructorDeObjectoAcciones()}
          />
        )}
        {origen === "Airbnb" && (
          <TablaDinamica
            datos={
              reservaciones &&
              reservaciones.airbnb
                .filter(
                  (reservacion) => reservacion.Codigo_de_reservacion !== null
                )
                .map((reservacion) => reservacionAirbnbParser(reservacion))
            }
            tieneBuscador={true}
          />
        )}
      </div>
      <ModalReservaciones
        visibilidad={modalCrear}
        setModal={setModalCrear}
        forzado={false}
      />
      <Modal visibilidad={modalVer} setModal={setModalVer}>
        <VisualizadorDeReservacion
          cerrarModal={() => setModalVer(false)}
          reservacion={reservacionEnfocada}
        />
      </Modal>
      <Modal visibilidad={modalEditar} forzado setModal={setModalEditar}>
        <EditorDeReservacion
          reservacion={reservacionEnfocada}
          cerrarModal={() => setModalEditar(false)}
        />
      </Modal>
      <Modal visibilidad={modalCancelar} forzado setModal={setModalCancelar}>
        <ConfirmacionDeCancelacion
          reservacion={reservacionEnfocada}
          cerrarModal={() => setModalCancelar(false)}
        />
      </Modal>
      <Modal visibilidad={modalBorrar} forzado setModal={setModalBorrar}>
        {reservacionEnfocada && (
          <ConfirmacionDeBorrado
            cerrarModal={() => setModalBorrar(false)}
            mensaje={`Desea borrar toda la infomación de la reservación
          ${reservacionEnfocada.Codigo_de_reservacion}`}
            funcionDeConfirmacion={() =>
              dispatch(
                ReservacionesActions.borrarReservacion(
                  reservacionEnfocada.Codigo_de_reservacion
                )
              )
            }
          />
        )}
      </Modal>
      <Modal
        visibilidad={modalEnvioResumen}
        forzado
        setModal={setModalEnvioResumen}
      >
        <ConfirmacionEnvioResumen
          reservacion={reservacionEnfocada}
          cerrarModal={() => setModalEnvioResumen(false)}
        />
      </Modal>
    </div>
  );
};

export default Reservaciones;
