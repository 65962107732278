import React from "react";
import Modal from "../../../Componentes/Modal/Modal";
import * as CarateristicasCabanas from "../../../consts/CaracteristicasCabanas";
import "./ModalReservaciones.css";

const ModalReservaciones = ({ visibilidad, setModal, forzado }) => {
  return (
    <Modal visibilidad={visibilidad} setModal={setModal} forzado={forzado}>
      <div className="modal-reservaciones">
        <p>Reservación</p>
        <div className="cuerpo-modal-resevaciones">
          <label>Cabaña</label>
          <select>
            {Object.keys(CarateristicasCabanas.datos).map((cabana, index) => (
              <option key={index}>{CarateristicasCabanas.datos[cabana].nombre}</option>
            ))}
          </select>
          <div className="div-nombre-modal-reservaciones">
            <label>Nombre del cliente</label>
            <input type="text" />
          </div>
          <div className="div-contacto-modal-reservaciones">
            <div className="div-telefono-modal-reservaciones">
              <label>Telefono</label>
              <input type="tel" />
            </div>
            <div className="div-correo-modal-reservaciones">
              <label>Correo electronico</label>
              <input type="email" />
            </div>
          </div>
          <div className="divs-huespedes-modal-reservaciones">
            <label>Numero de mayores de edad</label>
            <input type="number" />
          </div>
          <div className="divs-huespedes-modal-reservaciones">
            <label>Numero de mayores de 3 años</label>
            <input type="number" />
          </div>
          <div className="divs-huespedes-modal-reservaciones">
            <label>Numero de menores de 3 años</label>
            <input type="number" />
          </div>
          <div className="divs-huespedes-modal-reservaciones">
            <label>Numero de mascotas</label>
            <input type="number" />
          </div>
        </div>
        <div className="div-botones-modal-reservaciones">
          <button onClick={() => setModal(false)}>Cancelar</button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalReservaciones;
