import React, { Fragment, useEffect } from "react";
import "./VisualizadorDeReservacion.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as UsuariosActions from "../../redux/Actions/UsuariosActions";
import * as UsuariosSelectors from "../../redux/Selectors/UsuariosSelector";

//Utils
import fechaLargaConHorasMinutos from "../../utils/fechaLargaConHorasMinutos";
import fechaLarga from "../../utils/fechaLarga";
import SQLtoJSDateParser from "../../utils/SQLtoJSDateParser";
import switchNombreDeCabana from "../../utils/switchNombreDeCabana";
import binarioABoleano from "../../utils/binarioABoleano";

//Media
import * as GeneralSVG from "../../media/SVG/ExportadorGeneralSVG";

const VisualizadorDeReservacion = ({ reservacion, cerrarModal }) => {
  const dispatch = useDispatch();
  const usuarioEnfocado = useSelector((state) =>
    UsuariosSelectors.usuarioEnfocado(state)
  );
  useEffect(() => {
    if (usuarioEnfocado) {
      dispatch(UsuariosActions.borrarUsuarioEnfocado());
    }
    if (
      reservacion.Usuario_registrado &&
      binarioABoleano(reservacion.Usuario_registrado)
    ) {
      dispatch(UsuariosActions.getInfoUsuario(reservacion.ID_usuario));
    }
  }, [reservacion, dispatch]);
  return (
    <div id="visualizadorDeReservacion">
      {reservacion && (
        <Fragment>
          <div
            className={
              reservacion.Estatus === "Confirmada"
                ? "encabezado confirmada"
                : reservacion.Estatus === "Cancelada"
                ? "encabezado cancelada"
                : "encabezado"
            }
          >
            <h2>
              {reservacion.Codigo_de_reservacion} - {reservacion.Estatus}
            </h2>
            <p>
              Fecha de creación:{" "}
              {fechaLargaConHorasMinutos(
                new Date(reservacion.Fecha_de_confirmacion)
              )}
            </p>
          </div>
          <div className="datosCliente bloque">
            <h3>Datos del cliente</h3>
            <p>Nombre: {reservacion.Cliente}</p>
            <p>Teléfono: {reservacion.Telefono}</p>
            <p>Email: {reservacion.email}</p>
          </div>
          <div className="datosReservacion bloque">
            <h3>Datos de la reservación</h3>
            <p>
              Cabaña{reservacion.Cabana.split("-").length > 1 ? "s" : ""}{" "}
              {switchNombreDeCabana(reservacion.Cabana)}
            </p>
            <p>
              Entrada:{" "}
              {fechaLarga(SQLtoJSDateParser(reservacion.Fecha_de_entrada))}
            </p>
            <p>
              Salida:{" "}
              {fechaLarga(SQLtoJSDateParser(reservacion.Fecha_de_salida))}
            </p>
            {reservacion.Adultos > 0 && <p>{reservacion.Adultos} Adultos</p>}
            {reservacion.Ninos > 0 && (
              <p>{reservacion.Ninos} Menores de 10 años</p>
            )}
            {reservacion.Bebes > 0 && (
              <p>{reservacion.Bebes} Menores de 3 años</p>
            )}
            {reservacion.Mascotas > 0 && <p>{reservacion.Mascotas} Mascotas</p>}
          </div>
          <div className="datosBalance bloque">
            <h3>Balance</h3>
            <p>Costo Total: ${reservacion.Costo_total}</p>
            <p>
              Anticipo: ${reservacion.Anticipo ? reservacion.Anticipo : 0} -{" "}
              {reservacion.Metodo_de_pago}
            </p>
            <p>
              Pago pendiente: $
              {reservacion.Pago_pendiente
                ? reservacion.Pago_pendiente
                : reservacion.Costo_total}
            </p>
          </div>
        </Fragment>
      )}
      {usuarioEnfocado && (
        <div className="usuario">
          {!usuarioEnfocado.borradoLogico ? (
            <Fragment>
              <h3>Usuario que reservó</h3>
              <img
                src={
                  usuarioEnfocado.imagenPerfil
                    ? usuarioEnfocado.imagenPerfil
                    : GeneralSVG.Avatar_predeterminado
                }
              />
              <p>
                {usuarioEnfocado.nombre} {usuarioEnfocado.apellido}
              </p>
              <p>{usuarioEnfocado.email}</p>
              <p>{usuarioEnfocado.isAdmin ? "Administrador" : "Cliente"}</p>
            </Fragment>
          ) : (
            <h3>El usuario borró su cuenta</h3>
          )}
        </div>
      )}
      <div className="divBotones">
        <button
          className="botonCerrar"
          onClick={(e) => {
            e.preventDefault();
            if (usuarioEnfocado) {
              dispatch(UsuariosActions.borrarUsuarioEnfocado());
            }
            cerrarModal();
          }}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default VisualizadorDeReservacion;
