import { get } from "lodash";

export const demandaToda = (state) =>
  get(state, "EstadisticasReducer.demandaToda");
export const busquedas = (state) => get(state, "EstadisticasReducer.busquedas");
export const cotizaciones = (state) =>
  get(state, "EstadisticasReducer.cotizaciones");
export const eventosListoReservar = (state) =>
  get(state, "EstadisticasReducer.eventosListoReservar");
export const top20FechasProximasMasBuscadas = (state) =>
  get(state, "EstadisticasReducer.top20FechasProximasMasBuscadas");
export const confirmacionesPorFechas = (state) =>
  get(state, "EstadisticasReducer.confirmacionesPorFechas");
export const cargandoEstadisticas = (state) =>
  get(state, "EstadisticasReducer.cargandoEstadisticas");
