import React from "react";
import "./ConfiguradorDeFechas.css";

//Redux
import { useDispatch } from "react-redux";
import * as ReservacionesActions from "../../redux/Actions/ReservacionesActions";

//icons
import * as BsIcons from "react-icons/bs";

const ConfiguradorDeFechas = ({ cerrar, fechasSeleccionadas }) => {
  const dispatch = useDispatch();
  return (
    <div id="configuradorDeFechas">
      <BsIcons.BsFillXCircleFill
        className="iconoCerrar"
        onClick={() => cerrar()}
      />
      <div className="divBotones">
        <button
          onClick={() =>
            dispatch(ReservacionesActions.bloquearFechas(fechasSeleccionadas))
          }
        >
          Bloquear
          <BsIcons.BsFillLockFill />
        </button>
        <button
          onClick={() =>
            dispatch(
              ReservacionesActions.desbloquearFechas(fechasSeleccionadas)
            )
          }
        >
          Desbloquear
          <BsIcons.BsFillUnlockFill />
        </button>
        <select
          defaultValue={"Mínimo de noches"}
          onChange={(event) => {
            if (
              fechasSeleccionadas &&
              event.target.value !== "Mínimo de noches"
            ) {
              dispatch(
                ReservacionesActions.cambiarMinimoDeNoches({
                  fechas: fechasSeleccionadas,
                  minimoDeNoches:
                    event.target.value === "Sin restricción"
                      ? null
                      : event.target.value,
                })
              );
              event.target.value = "Mínimo de noches";
            }
          }}
        >
          <option value={"Mínimo de noches"} hidden>
            Mínimo de noches
          </option>
          <option>Sin restricción</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
        </select>
      </div>
    </div>
  );
};

export default ConfiguradorDeFechas;
