function binarioABoleano (binario){
    if(binario === 1){
        return true
    } else if(binario === 0){
        return false
    } else {
        throw "El parametro ingresado no es un valor binario 1 o 0"
    }
}

export default binarioABoleano