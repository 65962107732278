import * as types from "../actionTypes";

const initialState = {
  demandaToda: [],
  busquedas: [],
  cotizaciones: [],
  eventosListoReservar: [],
  top20FechasProximasMasBuscadas: [],
  confirmacionesPorFechas: {},
  cargandoEstadisticas: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ESTADISTICAS_DEMANDA_TODA:
      return {
        ...state,
        cargandoEstadisticas: true,
      };
    case types.GET_ESTADISTICAS_DEMANDA_TODA_SUCCESS:
      return {
        ...state,
        demandaToda: action.resultados.data,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_DEMANDA_TODA_ERROR:
      return {
        ...state,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_BUSQUEDAS:
      return {
        ...state,
        cargandoEstadisticas: true,
      };
    case types.GET_ESTADISTICAS_BUSQUEDAS_SUCCESS:
      return {
        ...state,
        busquedas: action.resultados.data,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_BUSQUEDAS_ERROR:
      return {
        ...state,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_COTIZACIONES:
      return {
        ...state,
        cargandoEstadisticas: true,
      };
    case types.GET_ESTADISTICAS_COTIZACIONES_SUCCESS:
      return {
        ...state,
        cotizaciones: action.resultados.data,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_COTIZACIONES_ERROR:
      return {
        ...state,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_LISTO_RESERVAR:
      return {
        ...state,
        cargandoEstadisticas: true,
      };
    case types.GET_ESTADISTICAS_LISTO_RESERVAR_SUCCESS:
      return {
        ...state,
        eventosListoReservar: action.resultados.data,
        cargandoEstadisticas: false,
      };
    case types.GET_ESTADISTICAS_LISTO_RESERVAR_ERROR:
      return {
        ...state,
        cargandoEstadisticas: false,
      };
    case types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS:
      return {
        ...state,
        cargandoEstadisticas: true,
      };
    case types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_SUCCESS:
      return {
        ...state,
        top20FechasProximasMasBuscadas: action.resultados.data,
        cargandoEstadisticas: false,
      };
    case types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_ERROR:
      return {
        ...state,
        cargandoEstadisticas: false,
      };
    case types.GET_CONFIRMACIONES_POR_FECHAS:
      return {
        ...state,
        cargandoEstadisticas: true,
      };
    case types.GET_CONFIRMACIONES_POR_FECHAS_SUCCESS:
      return {
        ...state,
        confirmacionesPorFechas: action.resultados.data,
        cargandoEstadisticas: false,
      };
    case types.GET_CONFIRMACIONES_POR_FECHAS_ERROR:
      return {
        ...state,
        cargandoEstadisticas: false,
      };
    default:
      return { ...state };
  }
}
