import anime from "animejs";

const CierreVistaGeneralFinanzas = (callbackAfter = () => {}) => {
  let tiempo = 1200;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "easeOutQuint",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".panel-finanzas .cuentas .cuenta",
      opacity: 0,
      duration: tiempo * 0.45,
    },
    0
  );
  timeline.add(
    {
      targets: ".panel-finanzas .cuentas .seleccionada",
      height: 500,
      width: 500,
      borderRadius: 250,
      duration: tiempo * 0.5,
    },
    0
  );
};

export default CierreVistaGeneralFinanzas;
