import * as types from "../actionTypes";

const initialState = {
  cuentas: [],
  movimientos: {},
  cargandoFinanzas: false,
  refrescar: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUENTAS_FINANZAS:
      return {
        ...state,
        cargandoFinanzas: true,
      };
    case types.GET_CUENTAS_FINANZAS_SUCCESS:
      return {
        ...state,
        cuentas: action.resultados.data,
        cargandoFinanzas: false,
        refrescar: false,
      };
    case types.GET_CUENTAS_FINANZAS_ERROR:
      return {
        ...state,
        cuentas: [],
        cargandoFinanzas: false,
        refrescar: false,
      };
    case types.GET_MOVIMIENTOS_MENSUALES:
      return {
        ...state,
        cargandoFinanzas: true,
      };
    case types.GET_MOVIMIENTOS_MENSUALES_SUCCESS:
      return {
        ...state,
        movimientos: action.resultados.data,
        cargandoFinanzas: false,
        refrescar: false,
      };
    case types.GET_MOVIMIENTOS_MENSUALES_ERROR:
      return {
        ...state,
        movimientos: {},
        cargandoFinanzas: false,
        refrescar: false,
      };
    case types.POST_INGRESO:
      return {
        ...state,
        cargandoFinanzas: true,
      };
    case types.POST_INGRESO_SUCCESS:
      return {
        ...state,
        cargandoFinanzas: false,
        refrescar: true,
      };
    case types.POST_INGRESO_ERROR:
      return {
        ...state,
        cargandoFinanzas: false,
      };
    case types.POST_EGRESO:
      return {
        ...state,
        cargandoFinanzas: true,
      };
    case types.POST_EGRESO_SUCCESS:
      return {
        ...state,
        cargandoFinanzas: false,
        refrescar: true,
      };
    case types.POST_EGRESO_ERROR:
      return {
        ...state,
        cargandoFinanzas: false,
      };
    default:
      return { ...state };
  }
}
