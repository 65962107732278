import { put, call, takeLatest, fork, delay, all } from "redux-saga/effects";
import { apiCall, admApiCall } from "../ApiCalls";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getCuponesTodosSaga({ payload }) {
  try {
    const resultados = yield call(apiCall, "GET", `cupones/todos`, null, {
      "Content-Type": "application/json",
      "x-auth-token": getToken(),
    });
    yield put({ type: types.GET_CUPONES_TODOS_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_CUPONES_TODOS_SUCCESS, payload });
  }
}

export function* postCuponSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "POST",
      `cupones/nuevo_cupon`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.POST_CUPON_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.POST_CUPON_ERROR, payload });
  }
}

export function* putCuponSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `cupones/actualizar_cupon`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.PUT_CUPON_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.PUT_CUPON_ERROR, payload });
  }
}

export function* activarDesactivarCuponSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `cupones/activar_desactivar_cupon`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.ACTIVAR_DESACTIVAR_CUPON_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.ACTIVAR_DESACTIVAR_CUPON_ERROR, payload });
  }
}

export function* ligarEmailCuponSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "PUT",
      `cupones/ligar_email_cupon`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.LIGAR_EMAIL_CUPON_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.LIGAR_EMAIL_CUPON_ERROR, payload });
  }
}

export function* deleteCuponSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "DELETE",
      `cupones/eliminar_cupon`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.DELETE_CUPON_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.DELETE_CUPON_ERROR, payload });
  }
}

export function* getTodasColeccionesCuponesSaga({ payload }) {
  try {
    const resultados = yield call(
      admApiCall,
      "GET",
      `colecciones_cupones/todas`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_TODAS_COLECCIONES_CUPONES_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_TODAS_COLECCIONES_CUPONES_ERROR, payload });
  }
}

export function* postColeccionCuponesCuponesSaga({ payload }) {
  try {
    const resultados = yield call(
      admApiCall,
      "POST",
      `colecciones_cupones/nueva_coleccion`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.POST_COLECCION_CUPONES_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.POST_COLECCION_CUPONES_ERROR, payload });
  }
}

export function* putColeccionCuponesCuponesSaga({ payload }) {
  try {
    const resultados = yield call(
      admApiCall,
      "PUT",
      `colecciones_cupones/actualizar_coleccion`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.PUT_COLECCION_CUPONES_SUCCESS, resultados });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.PUT_COLECCION_CUPONES_ERROR, payload });
  }
}

export function* deleteColeccionCuponesCuponesSaga({ payload }) {
  try {
    const resultados1 = yield call(
      admApiCall,
      "DELETE",
      `colecciones_cupones/eliminar_coleccion`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    const resultados2 = yield call(
      apiCall,
      "PUT",
      `cupones/desasociar_coleccion`,
      payload,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({ type: types.DELETE_COLECCION_CUPONES_SUCCESS, resultados1 });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.DELETE_COLECCION_CUPONES_ERROR, payload });
  }
}

export function* getCuponesYColeccionesSaga({ payload }) {
  try {
    const [cupones, colecciones] = yield all([
      call(apiCall, "GET", `cupones/todos`, null, {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }),
      call(admApiCall, "GET", `colecciones_cupones/todas`, null, {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }),
    ]);
    yield put({
      type: types.GET_CUPONES_Y_COLECCIONES_SUCCESS,
      cupones,
      colecciones,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_CUPONES_Y_COLECCIONES_ERROR, payload });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(types.GET_CUPONES_TODOS, getCuponesTodosSaga);
  yield takeLatest(types.POST_CUPON, postCuponSaga);
  yield takeLatest(types.PUT_CUPON, putCuponSaga);
  yield takeLatest(types.ACTIVAR_DESACTIVAR_CUPON, activarDesactivarCuponSaga);
  yield takeLatest(types.LIGAR_EMAIL_CUPON, ligarEmailCuponSaga);
  yield takeLatest(types.DELETE_CUPON, deleteCuponSaga);
  yield takeLatest(
    types.GET_TODAS_COLECCIONES_CUPONES,
    getTodasColeccionesCuponesSaga
  );
  yield takeLatest(
    types.POST_COLECCION_CUPONES,
    postColeccionCuponesCuponesSaga
  );
  yield takeLatest(types.PUT_COLECCION_CUPONES, putColeccionCuponesCuponesSaga);
  yield takeLatest(
    types.DELETE_COLECCION_CUPONES,
    deleteColeccionCuponesCuponesSaga
  );
  yield takeLatest(types.GET_CUPONES_Y_COLECCIONES, getCuponesYColeccionesSaga);
}
