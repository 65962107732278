import React, { Fragment } from "react";
import "./VisualizadorSobrereserva.css";

//Utils
import fechaLargaConHorasMinutos from "../../utils/fechaLargaConHorasMinutos";
import fechaLarga from "../../utils/fechaLarga";
import SQLtoJSDateParser from "../../utils/SQLtoJSDateParser";
import switchNombreDeCabana from "../../utils/switchNombreDeCabana";

const VisualizadorSobrereserva = ({ reservaciones, funcionCerrar }) => {
  return (
    <div id="visualizador-sobrereserva">
        <h2>Reservaciones conflictuadas</h2>
      <div className="div-reservaciones-visualizador-sobrereserva">
        {reservaciones.map((reservacion) => (
          <div className="reservacion-visualizador-sobrereserva">
            {reservacion.fuente === "directas" && (
              <Fragment>
                <div
                  className={
                    reservacion.Estatus === "Confirmada"
                      ? "encabezado confirmada"
                      : reservacion.Estatus === "Cancelada"
                      ? "encabezado cancelada"
                      : "encabezado"
                  }
                >
                  <h2>
                    {reservacion.Codigo_de_reservacion} - {reservacion.Estatus}
                  </h2>
                  <p>
                    Fecha de creación:{" "}
                    {fechaLargaConHorasMinutos(
                      new Date(reservacion.Fecha_de_confirmacion)
                    )}
                  </p>
                </div>
                <div className="datosCliente bloque">
                  <h3>Datos del cliente</h3>
                  <p>Nombre: {reservacion.Cliente}</p>
                  <p>Teléfono: {reservacion.Telefono}</p>
                  <p>Email: {reservacion.email}</p>
                </div>
                <div className="datosReservacion bloque">
                  <h3>Datos de la reservación</h3>
                  <p>
                    Cabaña
                    {reservacion.Cabana.split("-").length > 1 ? "s" : ""}{" "}
                    {switchNombreDeCabana(reservacion.Cabana)}
                  </p>
                  <p>
                    Entrada:{" "}
                    {fechaLarga(
                      SQLtoJSDateParser(reservacion.Fecha_de_entrada)
                    )}
                  </p>
                  <p>
                    Salida:{" "}
                    {fechaLarga(SQLtoJSDateParser(reservacion.Fecha_de_salida))}
                  </p>
                  {reservacion.Adultos > 0 && (
                    <p>{reservacion.Adultos} Adultos</p>
                  )}
                  {reservacion.Ninos > 0 && (
                    <p>{reservacion.Ninos} Menores de 10 años</p>
                  )}
                  {reservacion.Bebes > 0 && (
                    <p>{reservacion.Bebes} Menores de 3 años</p>
                  )}
                  {reservacion.Mascotas > 0 && (
                    <p>{reservacion.Mascotas} Mascotas</p>
                  )}
                </div>
                <div className="datosBalance bloque">
                  <h3>Balance</h3>
                  <p>Costo Total: ${reservacion.Costo_total}</p>
                  <p>
                    Anticipo: ${reservacion.Anticipo ? reservacion.Anticipo : 0}{" "}
                    - {reservacion.Metodo_de_pago}
                  </p>
                  <p>
                    Pago pendiente: $
                    {reservacion.Pago_pendiente
                      ? reservacion.Pago_pendiente
                      : reservacion.Costo_total}
                  </p>
                </div>
              </Fragment>
            )}
            {reservacion.fuente === "airbnb" && (
              <Fragment>
                <div className={"encabezado"}>
                  <h2>{reservacion.Codigo_de_reservacion}</h2>
                </div>
                <div className="datosReservacion bloque">
                  <h3>Datos de la reservación</h3>
                  <p>
                    Cabaña
                    {reservacion.Cabana.split("-").length > 1 ? "s" : ""}{" "}
                    {switchNombreDeCabana(reservacion.Cabana)}
                  </p>
                  <p>
                    Entrada:{" "}
                    {fechaLarga(
                      SQLtoJSDateParser(reservacion.Fecha_de_entrada)
                    )}
                  </p>
                  <p>
                    Salida:{" "}
                    {fechaLarga(SQLtoJSDateParser(reservacion.Fecha_de_salida))}
                  </p>
                  <h3>
                    <a href={reservacion["URL"]} target="_blank">
                      Ver más información
                    </a>
                  </h3>
                </div>
              </Fragment>
            )}
          </div>
        ))}
      </div>
      <button className="boton-cerrar" onClick={() => funcionCerrar()}>Cerrar</button>
    </div>
  );
};

export default VisualizadorSobrereserva;
