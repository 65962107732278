import React, { useEffect } from "react";
import "./Cargando.css";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

//Icons
import * as GeneralSVG from "../../media/SVG/ExportadorGeneralSVG";

const Cargando = () => {
  useEffect(() => {
    AnimacionesGenerales.CargandoLogoOutline();
  }, []);
  return (
    <div id="cargando">
      <GeneralSVG.LogoContorno id="cargandoPaginaPrincipal" />
    </div>
  );
};

export default Cargando;
