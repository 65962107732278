import anime from "animejs";

const CerrarConfiguradorDeFechas = (callbackAfter = () => {}) => {
  let tiempo = 200;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "easeInCubic",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: "#configuradorDeFechas",
      opacity: 0,
      top: "-200px",
      duration: tiempo,
    },
    0
  );
};

export default CerrarConfiguradorDeFechas;
