import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Finanzas.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as FinanzasActions from "../../redux/Actions/FinanzasActions";
import * as FinanzasSelectors from "../../redux/Selectors/FinanzasSelector";

//Componentes
import BotonNuevoIngreso from "../../Componentes/BotonNuevoIngreso/BotonNuevoIngreso";
import BotonNuevoEgreso from "../../Componentes/BotonNuevoEgreso/BotonNuevoEgreso";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

const Finanzas = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => FinanzasSelectors.cuentas(state));
  const refrescar = useSelector((state) => FinanzasSelectors.refrescar(state));
  const [dineroTotal, setDineroTotal] = useState(null);
  useEffect(() => {
    dispatch(FinanzasActions.getCuentasFinanzas());
  }, []);
  useEffect(() => {
    if (refrescar) dispatch(FinanzasActions.getCuentasFinanzas());
  }, [refrescar]);
  useEffect(() => {
    if (Array.isArray(cuentas) && cuentas.length > 1) {
      setDineroTotal(
        cuentas.reduce((acc, cur) => acc + cur.balance, 0).toFixed(2)
      );
    } else {
      setDineroTotal(null);
    }
  }, [cuentas]);
  return (
    <div className="router panel-finanzas">
      {dineroTotal && (
        <div className="total">
          <p className="nombre">Total</p>
          <p className="balance">${dineroTotal}</p>
        </div>
      )}
      {Array.isArray(cuentas) && cuentas.length > 0 ? (
        <div className="cuentas">
          {cuentas.map((cuenta, index) => (
            <div
              key={index}
              className="cuenta"
              onClick={(event) => {
                event.currentTarget.classList.add("seleccionada");
                AnimacionesGenerales.CierreVistaGeneralFinanzas(() =>
                  history.push(`/finanzas/cuenta/${cuenta._id}`)
                );
              }}
            >
              <p className="nombre">{cuenta.nombre}</p>
              <p className="balance">${cuenta.balance.toFixed(2)}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="sin-cuentas">
          <p>No hay cuentas disponibles</p>
        </div>
      )}
      <div className="botones-ingresos-egresos">
        <BotonNuevoIngreso />
        <BotonNuevoEgreso />
      </div>
    </div>
  );
};

export default Finanzas;
