import React, { useState, useEffect, Fragment } from "react";
import Swal from "sweetalert2";
import "./FormularioEgreso.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as FinanzasActions from "../../redux/Actions/FinanzasActions";
import * as FinanzasSelectors from "../../redux/Selectors/FinanzasSelector";

//consts
import categoriasEgresos from "../../consts/categoriasEgresos";

const FormularioEgreso = ({ cerrarModal, cuentaPredeterminada }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => FinanzasSelectors.cuentas(state));
  const [cuenta, setCuenta] = useState(
    cuentaPredeterminada ? cuentaPredeterminada : null
  );
  const [cantidad, setCantidad] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [categoria, setCategoria] = useState(categoriasEgresos[0]);
  const [facturado, setFacturado] = useState(false);
  useEffect(() => {
    if (!cuentaPredeterminada) {
      dispatch(FinanzasActions.getCuentasFinanzas());
    }
  }, []);
  return (
    <div className="formulario-egreso">
      <h1>Egreso</h1>
      {!cuentaPredeterminada && (
        <Fragment>
          <label>Cuenta</label>
          <div className="formulario-egreso-cuentas">
            {cuentas.map((dato, index) => (
              <div
                key={index}
                className={`formulario-egreso-cuenta ${
                  cuenta === dato._id ? "seleccionada" : ""
                }`}
                onClick={() => setCuenta(dato._id)}
              >
                {dato.nombre}
              </div>
            ))}
          </div>
        </Fragment>
      )}
      <label>Cantidad</label>
      <input
        type="number"
        value={cantidad}
        onChange={(event) => setCantidad(event.target.value)}
      />
      <label>Descripción</label>
      <input
        type="text"
        onChange={(event) => setDescripcion(event.target.value)}
      />
      <label>Facturado</label>
      <div
        className="formulario-egreso-facturado"
        onClick={() => setFacturado(!facturado)}
      >
        {facturado ? "Si" : "No"}
      </div>
      <label>Categoría</label>
      <select
        className="formulario-egreso-categorias"
        onChange={(event) => setCategoria(event.target.value)}
      >
        {categoriasEgresos.map((dato, index) => (
          <option
            key={index}
            className={`formulario-egreso-categoria ${
              categoria === dato ? "seleccionada" : ""
            }`}
          >
            {dato}
          </option>
        ))}
      </select>
      <div className="botones">
        <button
          onClick={() => {
            cerrarModal();
          }}
        >
          Cancelar
        </button>
        <button
          onClick={() => {
            if (!cuenta || !cantidad || !descripcion || !categoria)
            return Swal.fire("Complete todos los datos", "", "warning");
            let obj = {
              cuentaId: cuenta,
              cantidad: cantidad,
              descripcion: descripcion,
              facturado: facturado,
              fecha: new Date(Date.now()),
              categoria: categoria,
            };
            dispatch(FinanzasActions.postEgreso(obj));
            cerrarModal();
          }}
        >
          Registrar
        </button>
      </div>
    </div>
  );
};

export default FormularioEgreso;
