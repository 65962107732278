import * as types from "../actionTypes";

export const getReservaciones = (payload) => {
  return { type: types.GET_RESERVACIONES, payload };
};

export const getCosto = (payload) => {
  return {
    type: types.GET_COSTO,
    payload,
  };
};

export const resetCosto = (payload) => {
  return {
    type: types.RESET_COSTO,
    payload,
  };
};

export const putReservaciones = (payload) => {
  return { type: types.PUT_RESERVACION, payload };
};

export const cambiarEstatus = (payload) => {
  return { type: types.CAMBIAR_ESTATUS_RESERVACION, payload };
};

export const cancelarReservacion = (payload) => {
  return { type: types.CANCELAR_RESERVACION, payload };
};

export const cancelarReservacionYOrdenDePago = (payload) => {
  return { type: types.CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO, payload };
};

export const borrarReservacion = (payload) => {
  return { type: types.BORRAR_RESERVACION, payload };
};

export const enviarCorreoDeResumen = (payload) => {
  return { type: types.ENVIAR_CORREO_DE_RESUMEN, payload };
};

export const bloquearFechas = (payload) => {
  return { type: types.BLOQUEAR_FECHAS, payload };
};

export const desbloquearFechas = (payload) => {
  return { type: types.DESBLOQUEAR_FECHAS, payload };
};

export const cambiarMinimoDeNoches = (payload) => {
  return { type: types.CAMBIAR_MINIMO_DE_NOCHES, payload };
};
