import React, { useState, Fragment } from "react";
import "./BotonNuevoEgreso.css";

//Componentes
import Modal from "../../Componentes/Modal/Modal";
import FormularioEgreso from "../FormularioEgreso/FormularioEgreso";

//icons
import { GiPayMoney } from "react-icons/gi";

const BotonNuevoEgreso = ({cuentaPredeterminada}) => {
  const [modalEgreso, setModalEgreso] = useState(false);
  return (
    <Fragment>
      <div className="boton-nuevo-egreso" onClick={() => setModalEgreso(true)}>
        <GiPayMoney />
        <p>Egreso</p>
      </div>
      <Modal visibilidad={modalEgreso} setModal={setModalEgreso}>
        <FormularioEgreso
          cerrarModal={() => {
            setModalEgreso(false);
          }}
          cuentaPredeterminada={cuentaPredeterminada}
        />
      </Modal>
    </Fragment>
  );
};

export default BotonNuevoEgreso;
