//Reservaciones
export const GET_RESERVACIONES = "GET_RESERVACIONES"
export const GET_RESERVACIONES_SUCCESS = "GET_RESERVACIONES_SUCCESS"
export const GET_RESERVACIONES_ERROR = "GET_RESERVACIONES_ERROR"

export const GET_COSTO = "GET_COSTO"
export const GET_COSTO_SUCCESS = "GET_COSTO_SUCCESS"
export const GET_COSTO_ERROR = "GET_COSTO_ERROR"

export const RESET_COSTO = "RESET_COSTO"

export const PUT_RESERVACION = "PUT_RESERVACION"
export const PUT_RESERVACION_SUCCESS = "PUT_RESERVACION_SUCCESS"
export const PUT_RESERVACION_ERROR = "PUT_RESERVACION_ERROR"

export const CAMBIAR_ESTATUS_RESERVACION = "CAMBIAR_ESTATUS_RESERVACION"
export const CAMBIAR_ESTATUS_RESERVACION_SUCCESS = "CAMBIAR_ESTATUS_RESERVACION_SUCCESS"
export const CAMBIAR_ESTATUS_RESERVACION_ERROR = "CAMBIAR_ESTATUS_RESERVACION_ERROR"

export const CANCELAR_RESERVACION = "CANCELAR_RESERVACION"
export const CANCELAR_RESERVACION_SUCCESS = "CANCELAR_RESERVACION_SUCCESS"
export const CANCELAR_RESERVACION_ERROR = "CANCELAR_RESERVACION_ERROR"

export const CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO = "CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO"
export const CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_SUCCESS = "CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_SUCCESS"
export const CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_ERROR = "CANCELAR_RESERVACION_Y_ORDEN_DE_PAGO_ERROR"

export const BORRAR_RESERVACION = "BORRAR_RESERVACION"
export const BORRAR_RESERVACION_SUCCESS = "BORRAR_RESERVACION_SUCCESS"
export const BORRAR_RESERVACION_ERROR = "BORRAR_RESERVACION_ERROR"

export const ENVIAR_CORREO_DE_RESUMEN = "ENVIAR_CORREO_DE_RESUMEN"
export const ENVIAR_CORREO_DE_RESUMEN_SUCCESS = "ENVIAR_CORREO_DE_RESUMEN_SUCCESS"
export const ENVIAR_CORREO_DE_RESUMEN_ERROR = "ENVIAR_CORREO_DE_RESUMEN_ERROR"

export const BLOQUEAR_FECHAS = "BLOQUEAR_FECHAS"
export const BLOQUEAR_FECHAS_SUCCESS = "BLOQUEAR_FECHAS_SUCCESS"
export const BLOQUEAR_FECHAS_ERROR = "BLOQUEAR_FECHAS_ERROR"

export const DESBLOQUEAR_FECHAS = "DESBLOQUEAR_FECHAS"
export const DESBLOQUEAR_FECHAS_SUCCESS = "DESBLOQUEAR_FECHAS_SUCCESS"
export const DESBLOQUEAR_FECHAS_ERROR = "DESBLOQUEAR_FECHAS_ERROR"

export const CAMBIAR_MINIMO_DE_NOCHES = "CAMBIAR_MINIMO_DE_NOCHES"
export const CAMBIAR_MINIMO_DE_NOCHES_SUCCESS = "CAMBIAR_MINIMO_DE_NOCHES_SUCCESS"
export const CAMBIAR_MINIMO_DE_NOCHES_ERROR = "CAMBIAR_MINIMO_DE_NOCHES_ERROR"

//Auth
export const LOGIN_USUARIO_REGISTRADO = "LOGIN_USUARIO_REGISTRADO"
export const LOGIN_USUARIO_REGISTRADO_SUCCESS = "LOGIN_USUARIO_REGISTRADO_SUCCESS"
export const LOGIN_USUARIO_REGISTRADO_ERROR = "LOGIN_USUARIO_REGISTRADO_ERROR"

export const LOG_OUT = "LOG_OUT"

export const VERIFICA_ACCESS_TOKEN = "VERIFY_ACCESS_TOKEN"
export const VERIFICA_ACCESS_TOKEN_SUCCESS = "VERIFY_ACCESS_TOKEN_SUCCESS"
export const VERIFICA_ACCESS_TOKEN_ERROR = "VERIFY_ACCESS_TOKEN_ERROR"

export const PUT_IMAGEN_PERFIL = "PUT_IMAGEN_PERFIL"
export const PUT_IMAGEN_PERFIL_SUCCESS = "PUT_IMAGEN_PERFIL_SUCCESS"
export const PUT_IMAGEN_PERFIL_ERROR = "PUT_IMAGEN_PERFIL_ERROR"

//Users
export const GET_USUARIOS_TODOS = "GET_USUARIOS_TODOS"
export const GET_USUARIOS_TODOS_SUCCESS = "GET_USUARIOS_TODOS_SUCCESS"
export const GET_USUARIOS_TODOS_ERROR = "GET_USUARIOS_TODOS_ERROR"

export const GET_INFO_USUARIO = "GET_INFO_USUARIO"
export const GET_INFO_USUARIO_SUCCESS = "GET_INFO_USUARIO_SUCCESS"
export const GET_INFO_USUARIO_ERROR = "GET_INFO_USUARIO_ERROR"

export const BORRAR_USUARIO_ENFOCADO = "BORRAR_INFO_USUARIO"

export const REGISTRAR_USUARIO = "REGISTRAR_USUARIO"
export const REGISTRAR_USUARIO_SUCCESS = "REGISTRAR_USUARIO_SUCCESS"
export const REGISTRAR_USUARIO_ERROR = "REGISTRAR_USUARIO_ERROR"

export const ACTUALIZAR_USUARIO = "ACTUALIZAR_USUARIO"
export const ACTUALIZAR_USUARIO_SUCCESS = "ACTUALIZAR_USUARIO_SUCCESS"
export const ACTUALIZAR_USUARIO_ERROR = "ACTUALIZAR_USUARIO_ERROR"

export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO"
export const ELIMINAR_USUARIO_SUCCESS = "ELIMINAR_USUARIO_SUCCESS"
export const ELIMINAR_USUARIO_ERROR = "ELIMINAR_USUARIO_ERROR"

//Finanzas
export const GET_CUENTAS_FINANZAS = "GET_CUENTAS_FINANZAS"
export const GET_CUENTAS_FINANZAS_SUCCESS = "GET_CUENTAS_FINANZAS_SUCCESS"
export const GET_CUENTAS_FINANZAS_ERROR = "GET_CUENTAS_FINANZAS_ERROR"

export const GET_MOVIMIENTOS_MENSUALES = "GET_MOVIMIENTOS_MENSUALES"
export const GET_MOVIMIENTOS_MENSUALES_SUCCESS = "GET_MOVIMIENTOS_MENSUALES_SUCCESS"
export const GET_MOVIMIENTOS_MENSUALES_ERROR = "GET_MOVIMIENTOS_MENSUALES_ERROR"

export const POST_INGRESO = "POST_INGRESO"
export const POST_INGRESO_SUCCESS = "POST_INGRESO_SUCCESS"
export const POST_INGRESO_ERROR = "POST_INGRESO_ERROR"

export const POST_EGRESO = "POST_EGRESO"
export const POST_EGRESO_SUCCESS = "POST_EGRESO_SUCCESS"
export const POST_EGRESO_ERROR = "POST_EGRESO_ERROR"

//Cupones
export const GET_CUPONES_TODOS = "GET_CUPONES_TODOS"
export const GET_CUPONES_TODOS_SUCCESS = "GET_CUPONES_TODOS_SUCCESS"
export const GET_CUPONES_TODOS_ERROR = "GET_CUPONES_TODOS_ERROR"

export const POST_CUPON = "POST_CUPON"
export const POST_CUPON_SUCCESS = "POST_CUPON_SUCCESS"
export const POST_CUPON_ERROR = "POST_CUPON_ERROR"

export const PUT_CUPON = "PUT_CUPON"
export const PUT_CUPON_SUCCESS = "PUT_CUPON_SUCCESS"
export const PUT_CUPON_ERROR = "PUT_CUPON_ERROR"

export const ACTIVAR_DESACTIVAR_CUPON = "ACTIVAR_DESACTIVAR_CUPON"
export const ACTIVAR_DESACTIVAR_CUPON_SUCCESS = "ACTIVAR_DESACTIVAR_CUPON_SUCCESS"
export const ACTIVAR_DESACTIVAR_CUPON_ERROR = "ACTIVAR_DESACTIVAR_CUPON_ERROR"

export const LIGAR_EMAIL_CUPON = "LIGAR_EMAIL_CUPON"
export const LIGAR_EMAIL_CUPON_SUCCESS = "LIGAR_EMAIL_CUPON_SUCCESS"
export const LIGAR_EMAIL_CUPON_ERROR = "LIGAR_EMAIL_CUPON_ERROR"

export const DELETE_CUPON = "DELETE_CUPON"
export const DELETE_CUPON_SUCCESS = "DELETE_CUPON_SUCCESS"
export const DELETE_CUPON_ERROR = "DELETE_CUPON_ERROR"

//Cupones -------- Colecciones cupones
export const GET_TODAS_COLECCIONES_CUPONES = "GET_TODAS_COLECCIONES_CUPONES"
export const GET_TODAS_COLECCIONES_CUPONES_SUCCESS = "GET_TODAS_COLECCIONES_CUPONES_SUCCESS"
export const GET_TODAS_COLECCIONES_CUPONES_ERROR = "GET_TODAS_COLECCIONES_CUPONES_ERROR"

export const POST_COLECCION_CUPONES = "POST_COLECCION_CUPONES"
export const POST_COLECCION_CUPONES_SUCCESS = "POST_COLECCION_CUPONES_SUCCESS"
export const POST_COLECCION_CUPONES_ERROR = "POST_COLECCION_CUPONES_ERROR"

export const PUT_COLECCION_CUPONES = "PUT_COLECCION_CUPONES"
export const PUT_COLECCION_CUPONES_SUCCESS = "PUT_COLECCION_CUPONES_SUCCESS"
export const PUT_COLECCION_CUPONES_ERROR = "PUT_COLECCION_CUPONES_ERROR"

export const DELETE_COLECCION_CUPONES = "DELETE_COLECCION_CUPONES"
export const DELETE_COLECCION_CUPONES_SUCCESS = "DELETE_COLECCION_CUPONES_SUCCESS"
export const DELETE_COLECCION_CUPONES_ERROR = "DELETE_COLECCION_CUPONES_ERROR"

export const GET_CUPONES_Y_COLECCIONES = "GET_CUPONES_Y_COLECCIONES"
export const GET_CUPONES_Y_COLECCIONES_SUCCESS = "GET_CUPONES_Y_COLECCIONES_SUCCESS"
export const GET_CUPONES_Y_COLECCIONES_ERROR = "GET_CUPONES_Y_COLECCIONES_ERROR"

//Estadisticas
export const GET_ESTADISTICAS_DEMANDA_TODA = "GET_ESTADISTICAS_DEMANDA_TODA"
export const GET_ESTADISTICAS_DEMANDA_TODA_SUCCESS = "GET_ESTADISTICAS_DEMANDA_TODA_SUCCESS"
export const GET_ESTADISTICAS_DEMANDA_TODA_ERROR = "GET_ESTADISTICAS_DEMANDA_TODA_ERROR"

export const GET_ESTADISTICAS_BUSQUEDAS = "GET_ESTADISTICAS_BUSQUEDAS"
export const GET_ESTADISTICAS_BUSQUEDAS_SUCCESS = "GET_ESTADISTICAS_BUSQUEDAS_SUCCESS"
export const GET_ESTADISTICAS_BUSQUEDAS_ERROR = "GET_ESTADISTICAS_BUSQUEDAS_ERROR"

export const GET_ESTADISTICAS_COTIZACIONES = "GET_ESTADISTICAS_COTIZACIONES"
export const GET_ESTADISTICAS_COTIZACIONES_SUCCESS = "GET_ESTADISTICAS_COTIZACIONES_SUCCESS"
export const GET_ESTADISTICAS_COTIZACIONES_ERROR = "GET_ESTADISTICAS_COTIZACIONES_ERROR"

export const GET_ESTADISTICAS_LISTO_RESERVAR = "GET_ESTADISTICAS_LISTO_RESERVAR"
export const GET_ESTADISTICAS_LISTO_RESERVAR_SUCCESS = "GET_ESTADISTICAS_LISTO_RESERVAR_SUCCESS"
export const GET_ESTADISTICAS_LISTO_RESERVAR_ERROR = "GET_ESTADISTICAS_LISTO_RESERVAR_ERROR"

export const GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS = "GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS"
export const GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_SUCCESS = "GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_SUCCESS"
export const GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_ERROR = "GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_ERROR"

export const GET_CONFIRMACIONES_POR_FECHAS = "GET_CONFIRMACIONES_POR_FECHAS"
export const GET_CONFIRMACIONES_POR_FECHAS_SUCCESS = "GET_CONFIRMACIONES_POR_FECHAS_SUCCESS"
export const GET_CONFIRMACIONES_POR_FECHAS_ERROR = "GET_CONFIRMACIONES_POR_FECHAS_ERROR"