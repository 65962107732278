import React, { useState, useEffect, Fragment } from "react";
import Swal from "sweetalert2";
import "./FormularioIngreso.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as FinanzasActions from "../../redux/Actions/FinanzasActions";
import * as FinanzasSelectors from "../../redux/Selectors/FinanzasSelector";

//consts
import fuentesIngresos from "../../consts/fuentesIngresos";

const FormularioIngreso = ({ cerrarModal, cuentaPredeterminada }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => FinanzasSelectors.cuentas(state));
  const [cuenta, setCuenta] = useState(
    cuentaPredeterminada ? cuentaPredeterminada : null
  );
  const [cantidad, setCantidad] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [codigoDeReservacion, setCodigoDeReservacion] = useState(null);
  const [fuente, setFuente] = useState(fuentesIngresos[0]);
  useEffect(() => {
    if (!cuentaPredeterminada) {
      dispatch(FinanzasActions.getCuentasFinanzas());
    }
  }, []);
  return (
    <div className="formulario-ingreso">
      <h1>Ingreso</h1>
      {!cuentaPredeterminada && (
        <Fragment>
          <label>Cuenta</label>
          <div className="formulario-ingreso-cuentas">
            {cuentas.map((dato, index) => (
              <div
                key={index}
                className={`formulario-ingreso-cuenta ${
                  cuenta === dato._id ? "seleccionada" : ""
                }`}
                onClick={() => setCuenta(dato._id)}
              >
                {dato.nombre}
              </div>
            ))}
          </div>
        </Fragment>
      )}
      <label>Cantidad</label>
      <input
        type="number"
        value={cantidad}
        onChange={(event) => setCantidad(event.target.value)}
      />
      <label>Descripción</label>
      <input
        type="text"
        onChange={(event) => setDescripcion(event.target.value)}
      />
      <label>Código de Reservación (opcional)</label>
      <input
        type="text"
        onChange={(event) => setCodigoDeReservacion(event.target.value)}
      />
      <label>Fuente</label>
      <div className="formulario-ingreso-fuentes">
        {fuentesIngresos.map((dato, index) => (
          <div
            key={index}
            className={`formulario-ingreso-fuente ${
              fuente === dato ? "seleccionada" : ""
            }`}
            onClick={() => setFuente(dato)}
          >
            {dato}
          </div>
        ))}
      </div>
      <div className="botones">
        <button
          onClick={() => {
            cerrarModal();
          }}
        >
          Cancelar
        </button>
        <button
          onClick={() => {
            if (!cuenta || !cantidad || !descripcion || !fuente)
              return Swal.fire("Complete todos los datos", "", "warning");
            let obj = {
              cuentaId: cuenta,
              cantidad: cantidad,
              descripcion: descripcion,
              fecha: new Date(Date.now()),
              fuente: fuente,
            };
            if (codigoDeReservacion)
              Object.assign(obj, { codigoDeReservacion: codigoDeReservacion });
            dispatch(FinanzasActions.postIngreso(obj));
            cerrarModal();
          }}
        >
          Registrar
        </button>
      </div>
    </div>
  );
};

export default FormularioIngreso;
