import * as types from "../actionTypes";
import { setToken, eraseToken } from "../../services/tokenServices";

const initialState = {
  userInfo: null,
  logged: false,
  token: null,
  cargandoAuth: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_USUARIO_REGISTRADO:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.LOGIN_USUARIO_REGISTRADO_SUCCESS:
      setToken(action.resultados.headers["x-auth-token"]);
      return {
        ...state,
        userInfo: action.resultados.data,
        logged: true,
        token: action.resultados.headers["x-auth-token"],
        cargandoAuth: false,
      };
    case types.LOGIN_USUARIO_REGISTRADO_ERROR:
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
        cargandoAuth: false,
      };
    case types.REGISTRAR_USUARIO:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.REGISTRAR_USUARIO_SUCCESS:
      setToken(action.resultados.headers["x-auth-token"]);
      return {
        ...state,
        userInfo: action.resultados.data,
        logged: true,
        token: action.resultados.headers["x-auth-token"],
        cargandoAuth: false,
      };
    case types.REGISTRAR_USUARIO_ERROR:
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
        cargandoAuth: false,
      };
    case types.LOG_OUT:
      eraseToken();
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
      };
    case types.VERIFICA_ACCESS_TOKEN_SUCCESS:
      return {
        userInfo: action.resultados.data,
        logged: true,
      };
    case types.VERIFICA_ACCESS_TOKEN_ERROR:
      eraseToken();
      return {
        ...state,
        userInfo: null,
        logged: false,
        token: null,
      };
    case types.PUT_IMAGEN_PERFIL:
      return {
        ...state,
        cargandoAuth: true,
      };
    case types.PUT_IMAGEN_PERFIL_SUCCESS:
      return {
        ...state,
        userInfo: action.resultados.data,
        cargandoAuth: false,
      };
    case types.PUT_IMAGEN_PERFIL_ERROR:
      return {
        ...state,
        cargandoAuth: false,
      };
    default:
      return { ...state };
  }
}
