import * as types from "../actionTypes";

const initialState = {
  todosLosUsuarios: [],
  usuarioEnfocado: null,
  refrescarUsuarios: false,
  cargandoUsuarios: false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_USUARIOS_TODOS:
      return {
        ...state,
        cargandoUsuarios: true,
      };
    case types.GET_USUARIOS_TODOS_SUCCESS:
      return {
        ...state,
        todosLosUsuarios: action.resultados.data,
        cargandoUsuarios: false,
        refrescarUsuarios: false,
      };
    case types.GET_USUARIOS_TODOS_ERROR:
      return {
        ...state,
        todosLosUsuarios: [],
        cargandoUsuarios: false,
      };
    case types.GET_INFO_USUARIO:
      return {
        ...state,
        cargandoUsuarios: true,
      };
    case types.GET_INFO_USUARIO_SUCCESS:
      return {
        ...state,
        usuarioEnfocado: action.resultados.data,
        cargandoUsuarios: false,
      };
    case types.GET_INFO_USUARIO_ERROR:
      return {
        ...state,
        usuarioEnfocado: null,
        cargandoUsuarios: false,
      };
    case types.REGISTRAR_USUARIO:
      return {
        ...state,
        cargandoUsuarios: true,
      };
    case types.REGISTRAR_USUARIO_SUCCESS:
      return {
        ...state,
        cargandoUsuarios: false,
        refrescarUsuarios: true,
      };
    case types.REGISTRAR_USUARIO_ERROR:
      return {
        ...state,
        cargandoUsuarios: false,
      };
    case types.BORRAR_USUARIO_ENFOCADO:
      return {
        ...state,
        usuarioEnfocado: null,
      };
    case types.ACTUALIZAR_USUARIO:
      return {
        ...state,
        cargandoUsuarios: true,
      };
    case types.ACTUALIZAR_USUARIO_SUCCESS:
      return {
        ...state,
        usuarioEnfocado: null,
        cargandoUsuarios: false,
        refrescarUsuarios: true,
      };
    case types.ACTUALIZAR_USUARIO_ERROR:
      return {
        ...state,
        usuarioEnfocado: null,
        cargandoUsuarios: false,
      };
    case types.ELIMINAR_USUARIO:
      return {
        ...state,
        cargandoUsuarios: true,
      };
    case types.ELIMINAR_USUARIO_SUCCESS:
      return {
        ...state,
        usuarioEnfocado: null,
        cargandoUsuarios: false,
        refrescarUsuarios: true,
      };
    case types.ELIMINAR_USUARIO_ERROR:
      return {
        ...state,
        usuarioEnfocado: null,
        cargandoUsuarios: false,
      };
    default:
      return { ...state };
  }
}
