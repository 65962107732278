export default [
  "Servicios",
  "Insumos",
  "Mercadeo",
  "Sueldos",
  "Mobiliario",
  "Equipo",
  "Mantenimiento",
  "Construccion",
  "Traslado"
];
