import * as types from "../actionTypes";

export const getUsuariosTodos = (payload) => {
  return {
    type: types.GET_USUARIOS_TODOS,
    payload,
  };
};

export const getInfoUsuario = (payload) => {
  return {
    type: types.GET_INFO_USUARIO,
    payload,
  };
};

export const registrarUsuario = (payload) => {
  return {
    type: types.REGISTRAR_USUARIO,
    payload,
  };
};

export const borrarUsuarioEnfocado = (payload) => {
  return {
    type: types.BORRAR_USUARIO_ENFOCADO,
    payload,
  };
};

export const actualizarUsuario = (payload) => {
  return {
    type: types.ACTUALIZAR_USUARIO,
    payload,
  };
};

export const eliminarUsuario = (payload) => {
  return {
    type: types.ELIMINAR_USUARIO,
    payload,
  };
};
