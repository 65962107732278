function fechaEntreRango(fechaAVerificar, minimo, maximo) {
  let fechaMinima = new Date(minimo);
  fechaMinima.setHours(0, 0, 0)
  fechaMinima = Date.parse(fechaMinima);
  let fechaMaxima = new Date(maximo);
  fechaMaxima.setHours(0, 0, 0)
  fechaMaxima = Date.parse(fechaMaxima);
  let fechaAVerificarMilisegundos = new Date(fechaAVerificar);
  fechaAVerificarMilisegundos.setHours(0, 0, 0);
  fechaAVerificarMilisegundos = Date.parse(fechaAVerificarMilisegundos);

  if (
    fechaAVerificarMilisegundos >= fechaMinima &&
    fechaAVerificarMilisegundos <= fechaMaxima
  ) {
    return true;
  } else {
    return false;
  }
}

module.exports = fechaEntreRango;
