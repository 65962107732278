import { get } from "lodash";

export const reservaciones = (state) =>
  get(state, "ReservacionesReducer.reservaciones");
export const costoTotal = (state) =>
  get(state, "ReservacionesReducer.costoTotal");
export const refrescarReservaciones = (state) =>
  get(state, "ReservacionesReducer.refrescarReservaciones");
export const cargandoBloqueoODesbloqueo = (state) =>
  get(state, "ReservacionesReducer.cargandoBloqueoODesbloqueo");
export const fechasBloqueadas = (state) =>
  get(state, "ReservacionesReducer.fechasBloqueadas");
export const tarifas = (state) => get(state, "ReservacionesReducer.tarifas");
export const minimoNochesExcepciones = (state) =>
  get(state, "ReservacionesReducer.minimoNochesExcepciones");
export const cargandoReservaciones = (state) =>
  get(state, "ReservacionesReducer.cargandoReservaciones");
