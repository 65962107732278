import anime from "animejs";

const EntraBarraLateral = (callbackAfter = () => {}) => {
  let tiempo = 500;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "easeInOutSine",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: ".barra-lateral",
      left: 0,
      duration: 0.8 * tiempo,
    },
    0.2 * tiempo
  );
};

export default EntraBarraLateral;
