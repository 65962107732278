import anime from "animejs";

const AbrirConfiguradorDeFechas = (callbackAfter = () => {}) => {
  let tiempo = 400;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "easeInCubic",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: "#configuradorDeFechas",
      opacity: 1,
      top: "0px",
      duration: tiempo,
    },
    0
  );
};

export default AbrirConfiguradorDeFechas;
