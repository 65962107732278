export const getToken = () => {
  return localStorage.getItem("access_cima_calidez");
};

export const setToken = (token) => {
  localStorage.setItem("access_cima_calidez", token);
};

export const eraseToken = () => {
    localStorage.removeItem("access_cima_calidez");
  };
