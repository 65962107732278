import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postColeccionCupones } from "../../redux/Actions/CuponesActions";
import Swal from "sweetalert2";
import "./NuevaColeccionCupones.css";

const NuevaColeccionCupones = ({ funcionCancelar }) => {
  const dispatch = useDispatch();
  const [nombre, setNombre] = useState(undefined);
  const [descripcion, setDescripcion] = useState(undefined);
  return (
    <div className="nueva-coleccion-cupones">
      <div className="div-nombre-descripcion-nueva-coleccion">
        <h2>Nombre</h2>
        <input type="text" onChange={(e) => setNombre(e.target.value)} />
        <h2>Descripción</h2>
        <textarea onChange={(e) => setDescripcion(e.target.value)} />
      </div>
      <div className="div-botones-nueva-coleccion">
        <button onClick={() => funcionCancelar()}>Cancelar</button>
        <button
          onClick={() => {
            if (nombre && descripcion) {
              dispatch(postColeccionCupones({ nombre, descripcion }));
            } else {
              Swal.fire("Complete los datos", "", "error");
            }
          }}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

export default NuevaColeccionCupones;
