import anime from "animejs";

const FinalizarCargando = (callbackAfter = () => {}) => {
  let tiempo = 500;
  let timeline = anime.timeline({
    duration: tiempo,
    easing: "linear",
    complete: () => callbackAfter(),
  });
  timeline.add(
    {
      targets: "#cargando",
      opacity: 0,
      duration: tiempo,
    },
    0
  );
};

export default FinalizarCargando;
