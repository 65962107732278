import React, { useState, Fragment } from "react";
import "./BotonNuevoIngreso.css";

//Componentes
import Modal from "../../Componentes/Modal/Modal";
import FormularioIngreso from "../FormularioIngreso/FormularioIngreso";

//icons
import { GiReceiveMoney } from "react-icons/gi";

const BotonNuevoIngreso = ({ cuentaPredeterminada }) => {
  const [modalIngreso, setModalIngreso] = useState(false);
  return (
    <Fragment>
      <div
        className="boton-nuevo-ingreso"
        onClick={() => setModalIngreso(true)}
      >
        <GiReceiveMoney />
        <p>Ingreso</p>
      </div>
      <Modal visibilidad={modalIngreso} setModal={setModalIngreso}>
        <FormularioIngreso
          cerrarModal={() => {
            setModalIngreso(false);
          }}
          cuentaPredeterminada={cuentaPredeterminada}
        />
      </Modal>
    </Fragment>
  );
};

export default BotonNuevoIngreso;
