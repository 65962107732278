import * as types from "../actionTypes";

export const loginUsuarioRegistrado = (payload) => {
  return {
    type: types.LOGIN_USUARIO_REGISTRADO,
    payload,
  };
};

export const logOut = (payload) => {
  return {
    type: types.LOG_OUT,
    payload,
  };
};

export const verificaAccessToken = (payload) => {
  return {
    type: types.VERIFICA_ACCESS_TOKEN,
    payload,
  };
};

export const putImagenDePerfil = (payload) => {
  return {
    type: types.PUT_IMAGEN_PERFIL,
    payload,
  };
};
