import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Estadisticas.css";

//Redux
import * as EstadisticasActions from "../../redux/Actions/EstadisticasActions";
import * as EstadisticasSelector from "../../redux/Selectors/EstadisticasSelector";

//Graficas
import DemandaFechasBarras from "./Graficas/DemandaFechasBarras";
import LineaFechas from "./Graficas/LineaFechas";

//utils
import sqlToJsDate from "../../utils/SQLtoJSDateParser";

//variables
const milisegundosDeUnDia = 86400000;

const Estadisticas = () => {
  const dispatch = useDispatch();
  const top20FechasProximasMasBuscadas = useSelector((state) =>
    EstadisticasSelector.top20FechasProximasMasBuscadas(state)
  );
  const confirmacionesPorFechas = useSelector((state) =>
    EstadisticasSelector.confirmacionesPorFechas(state)
  );
  useEffect(() => {
    dispatch(EstadisticasActions.getTop20FechasProximasMasBuscadas());
    dispatch(EstadisticasActions.getConfirmacionesPorFechas());
  }, [dispatch]);
  const parserconfirmacionesPorFechas = (objeto) => {
    let arr = [];
    for (const fecha in objeto) {
      if (
        Object.hasOwnProperty.call(objeto, fecha) &&
        Date.parse(sqlToJsDate(fecha)) > Date.now() - milisegundosDeUnDia * 60
      ) {
        arr.push({
          fecha: sqlToJsDate(fecha),
          frecuencia: objeto[fecha],
        });
      }
    }
    return arr;
  };

  return (
    <div className="router panel-estadisticas">
      <div className="top-20-fechas">
        <h1>Top 20 fechas próximas más buscadas</h1>
        <DemandaFechasBarras datos={top20FechasProximasMasBuscadas} />
      </div>
      <div className="confirmaciones-fechas">
        <h1>Confirmaciones en los últimos 60 días</h1>
        <LineaFechas
          datos={parserconfirmacionesPorFechas(confirmacionesPorFechas)}
        />
      </div>
    </div>
  );
};

export default Estadisticas;
