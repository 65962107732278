import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ReservacionesReducer from "./ReservacionesReducer";
import UsuariosReducer from "./UsuariosReducer";
import FinanzasReducer from "./FinanzasReducer";
import CuponesReducer from "./CuponesReducer";
import EstadisticasReducer from "./EstadisticasReducer";

const rootReducer = combineReducers({
  AuthReducer,
  ReservacionesReducer,
  UsuariosReducer,
  FinanzasReducer,
  CuponesReducer,
  EstadisticasReducer,
});

export default rootReducer;
