import axios from 'axios';
import GeneralUrls from "../consts/GeneralUrls"

const baseUrl = GeneralUrls.reservaciones_api
const authUrl = GeneralUrls.authentication_api
const admApiUrl = GeneralUrls.administracion_api
const actApiUrl = GeneralUrls.actividad_api

export const apiCall = (method, url, data, headers) => axios({ method, url: baseUrl + url, data, headers })
export const authCall = (method, url, data, headers) => axios({ method, url: authUrl + url, data, headers })
export const admApiCall = (method, url, data, headers) => axios({ method, url: admApiUrl + url, data, headers })
export const actApiCall = (method, url, data, headers) => axios({ method, url: actApiUrl + url, data, headers })