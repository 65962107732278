import React from "react";

import * as d3 from "d3";

//Hooks
import useD3 from "../../../Hooks/useD3";
import useWindowSize from "../../../Hooks/useWindowsSize";

const LineaFechas = ({ datos }) => {
  const [anchoVentana, altoVentana] = useWindowSize();
  const height = altoVentana < 500 ? altoVentana : 500;
  const width = anchoVentana < 600 ? anchoVentana : 600;
  const ref = useD3(
    (svg) => {
      const margin = { top: 20, right: 30, bottom: 80, left: 40 };
      const accesoX = (d) => d.fecha;
      const accesoY = (d) => d.frecuencia;

      const x = d3
        .scaleTime()
        .domain(d3.extent(datos, accesoX))
        .range([margin.left, width - margin.right]);

      const y1 = d3
        .scaleLinear()
        .domain(d3.extent(datos, accesoY))
        .range([height - margin.bottom, margin.top]);

      const xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(d3.axisBottom(x).tickSize(0))
          .selectAll("text")
          .style("text-anchor", "end")
          .style("font-size", "16px")
          .style("font-weight", "bold")
          .style("color", "var(--colores-principales-cima-cafe)")
          .attr("dx", "-0.5em")
          .attr("dy", "2px")
          .attr("transform", "rotate(-90)");

      const y1Axis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .style("color", "var(--colores-principales-cima-cafe)")
          .call(d3.axisLeft(y1).ticks(null, "s"))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
          );

      svg.select(".x-axis").call(xAxis);
      svg.select(".y-axis").call(y1Axis);

      const generadorDeLinea = d3
        .line()
        .x((d) => x(accesoX(d)))
        .y((d) => y1(accesoY(d)))
        .curve(d3.curveBasis);

      svg
        .select(".plot-area")
        .attr("d", generadorDeLinea(datos))
        .attr("fill", "none")
        .attr("stroke", "var(--colores-principales-cima-verde)")
        .attr("stroke-width", 2);
    },
    [datos.length]
  );

  return (
    <svg
      ref={ref}
      style={{
        height: height,
        width: width,
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <path className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg>
  );
};

export default LineaFechas;
