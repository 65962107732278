import * as types from "../actionTypes";

export const getCuentasFinanzas = (payload) => {
  return {
    type: types.GET_CUENTAS_FINANZAS,
    payload,
  };
};

export const getMovimientosMensuales = (payload) => {
  return {
    type: types.GET_MOVIMIENTOS_MENSUALES,
    payload,
  };
};

export const postIngreso = (payload) => {
  return {
    type: types.POST_INGRESO,
    payload,
  };
};

export const postEgreso = (payload) => {
  return {
    type: types.POST_EGRESO,
    payload,
  };
};
