function calcularEdad(fecha) {
  let fechaFormato = new Date(fecha);
  let fechaHoyFormato = new Date(Date.now());
  let edad = fechaHoyFormato.getFullYear() - fechaFormato.getFullYear();
  if (fechaHoyFormato.getMonth() < fechaFormato.getMonth()) {
    edad--;
  } else if (
    fechaHoyFormato.getMonth() === fechaFormato.getMonth() &&
    fechaHoyFormato.getDate() <= fechaFormato.getDate()
  ) {
    edad--;
  }
  return edad
}

export default calcularEdad
