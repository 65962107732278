import * as types from "../actionTypes";

export const getEstadisticasDemandaToda = (payload) => {
  return {
    type: types.GET_ESTADISTICAS_DEMANDA_TODA,
    payload,
  };
};

export const getTop20FechasProximasMasBuscadas = (payload) => {
  return {
    type: types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS,
    payload,
  };
};

export const getEstadisticasBusquedas = (payload) => {
  return {
    type: types.GET_ESTADISTICAS_BUSQUEDAS,
    payload,
  };
};

export const getEstadisticasCotizaciones = (payload) => {
  return {
    type: types.GET_ESTADISTICAS_COTIZACIONES,
    payload,
  };
};

export const getEstadisticasListoReservar = (payload) => {
  return {
    type: types.GET_ESTADISTICAS_LISTO_RESERVAR,
    payload,
  };
};

export const getConfirmacionesPorFechas = (payload) => {
  return {
    type: types.GET_CONFIRMACIONES_POR_FECHAS,
    payload,
  };
};


