import GeneralUrls from "./GeneralUrls";

// Planos
// import planoAveAzul from "../assets/imagenes/PlanoAveAzul.png";
// import planoJilguero from "../assets/imagenes/PlanoJilguero.png";
// import planoTigrillo from "../assets/imagenes/PlanoTigrillo.png";
// import planoColibri from "../assets/imagenes/PlanoColibri.png";
// import planoCoa from "../assets/imagenes/PlanoCoa.png";

export const datos = {
  Cabana_1: {
    Id: "1",
    nombre: "Ave azul",
    capacidadMax: 12,
    capacidadMin: 3,
    precioBase: 1000,
    precioBaseAlta: 2000,
    precioBaseFestivo: 3000,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 4,
    camasIndividual: 2,
    sofaCama: 0,
    literas: 0,
    inodoros: 2,
    regaderas: 2,
    longitud_galeria: 37,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Ave_azul`,
    // plano: planoAveAzul,
    videoVistas: undefined
  },
  Cabana_2: {
    Id: "2",
    nombre: "Jilguero",
    capacidadMax: 6,
    capacidadMin: 2,
    precioBase: 750,
    precioBaseAlta: 1750,
    precioBaseFestivo: 3750,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 0,
    camasIndividual: 0,
    sofaCama: 0,
    literas: 2,
    inodoros: 1,
    regaderas: 1,
    longitud_galeria: 29,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Jilguero`,
    // plano: planoJilguero,
    videoVistas: undefined
  },
  Cabana_3: {
    Id: "3",
    nombre: "Tigrillo",
    capacidadMax: 8,
    capacidadMin: 3,
    precioBase: 1000,
    precioBaseAlta: 2000,
    precioBaseFestivo: 3000,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 1,
    camasIndividual: 0,
    sofaCama: 1,
    literas: 2,
    inodoros: 2,
    regaderas: 1,
    longitud_galeria: 32,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Tigrillo`,
    // plano: planoTigrillo,
    videoVistas: undefined
  },
  Cabana_4: {
    Id: "4",
    nombre: "Colibrí",
    capacidadMax: 6,
    capacidadMin: 2,
    precioBase: 850,
    precioBaseAlta: 1850,
    precioBaseFestivo: 2850,
    camasKing: 1,
    camasQueen: 0,
    camasMatrimonial: 2,
    camasIndividual: 0,
    sofaCama: 0,
    literas: 0,
    inodoros: 2,
    regaderas: 2,
    longitud_galeria: 33,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Colibri`,
    // plano: planoColibri,
    videoVistas: undefined
  },
  Cabana_5: {
    Id: "5",
    nombre: "Coa",
    capacidadMax: 7,
    capacidadMin: 2,
    precioBase: 950,
    precioBaseAlta: 1950,
    precioBaseFestivo: 2950,
    camasKing: 0,
    camasQueen: 1,
    camasMatrimonial: 2,
    camasIndividual: 1,
    sofaCama: 1,
    literas: 0,
    inodoros: 2,
    regaderas: 2,
    longitud_galeria: 39,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Coa`,
    // plano: planoCoa,
    videoVistas: undefined
  },
  Cabana_6: {
    Id: "6",
    nombre: "Búho",
    capacidadMax: 2,
    capacidadMin: 2,
    admiteNinos: false,
    admiteMascotas: false,
    elCostoVariaSegunHuespedes: false,
    precioBase: 1600,
    precioBaseAlta: 2100,
    precioBaseFestivo: 2600,
    camasKing: 0,
    camasQueen: 1,
    camasMatrimonial: 0,
    camasIndividual: 0,
    sofaCama: 1,
    literas: 0,
    inodoros: 1,
    regaderas: 1,
    longitud_galeria: 21,
    rutaGaleria: `${GeneralUrls.landing_page}recursos/Mas_detalles/Buho`,
    plano: undefined,
    videoVistas:
      "https://www.youtube.com/embed/qO0ln9GXdUo?controls=0&autoplay=1&loop=1&playlist=qO0ln9GXdUo",
  },
};

export const costosAdicionales = {
  persona: 250,
  mascota: 150,
};

export const cantidadMaximaDeHuespedes = () => {
  return Object.keys(datos).reduce(
    (acc, cur) => acc + datos[cur].capacidadMax,
    0
  );
};

export const cantidadMinimaDeHuespedes = () => {
  return Object.keys(datos).reduce(
    (acc, cur) => acc + datos[cur].capacidadMin,
    0
  );
};

export const cantidadMaximaDeBebes = 5;

export const cantidadMaximaDeMascotas = 5;
