import * as types from "../actionTypes";

export const getCuponesTodos = (payload) => {
  return {
    type: types.GET_CUPONES_TODOS,
    payload,
  };
};

export const postCupon = (payload) => {
  return {
    type: types.POST_CUPON,
    payload,
  };
};

export const putCupon = (payload) => {
  return {
    type: types.PUT_CUPON,
    payload,
  };
};

export const activarDesactivarCupon = (payload) => {
  return {
    type: types.ACTIVAR_DESACTIVAR_CUPON,
    payload,
  };
};

export const ligarEmailCupon = (payload) => {
  return {
    type: types.LIGAR_EMAIL_CUPON,
    payload,
  };
};

export const deleteCupon = (payload) => {
  return {
    type: types.DELETE_CUPON,
    payload,
  };
};

export const getTodasColeccionesCupones = (payload) => {
  return {
    type: types.GET_TODAS_COLECCIONES_CUPONES,
    payload,
  };
};

export const postColeccionCupones = (payload) => {
  return {
    type: types.POST_COLECCION_CUPONES,
    payload,
  };
};

export const putColeccionCupones = (payload) => {
  return {
    type: types.PUT_COLECCION_CUPONES,
    payload,
  };
};

export const deleteColeccionCupones = (payload) => {
  return {
    type: types.DELETE_COLECCION_CUPONES,
    payload,
  };
};

export const getCuponesYColecciones = (payload) => {
  return {
    type: types.GET_CUPONES_Y_COLECCIONES,
    payload,
  };
};

