import React, { useState } from "react";
import "./Modal.css";

const Modal = ({ children, visibilidad, setModal, forzado }) => {
  const [visible, setVisible] = useState(visibilidad);
  if (!visible && visibilidad) {
    setVisible(true);
  } else if (visible && !visibilidad) {
    setTimeout(() => {
      setVisible(false);
    }, 450);
  }
  if (visible) {
    return (
      <div
        id={visibilidad ? "modal" : "cierra-modal"}
        onClick={(event) => {
          if (event.target.id === "modal" && !forzado && setModal) {
            event.target.id = "cierra-modal";
            setTimeout(() => {
              setModal(false);
              setVisible(false);
            }, 450);
          }
        }}
      >
        {children}
      </div>
    );
  } else {
    return false;
  }
};

export default Modal;
