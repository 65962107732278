import React from "react";
import "./ConfirmacionDeBorrado.css";


const ConfirmacionDeBorrado = ({
  cerrarModal,
  mensaje,
  funcionDeConfirmacion,
}) => {
  return (
    <div className="modalConfirmacionDeBorrado">
      <p>{mensaje}</p>
      <button
        className="botonNo"
        onClick={() => {
          cerrarModal();
        }}
      >
        No
      </button>
      <button className="bottonSi" onClick={() => funcionDeConfirmacion()}>
        Si
      </button>
    </div>
  );
};

export default ConfirmacionDeBorrado;
