import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";

//Redux
import { useSelector } from "react-redux";
import * as AuthSelector from "../redux/Selectors/AuthSelector";
import * as ReservacionesSelector from "../redux/Selectors/ReservacionesSelector";
import * as UsuariosSelector from "../redux/Selectors/UsuariosSelector";
import * as FinanzasSelector from "../redux/Selectors/FinanzasSelector";
import * as CuponesSelector from "../redux/Selectors/CuponesSelector";

//Componentes
import BarraLateral from "../Componentes/BarraLateral/BarraLateral";
import PrivateRoute from "./PrivateRoute";
import ReduxListener from "./ReduxListener";
import Cargando from "../Componentes/Cargando/Cargando";

//Componenetes de Rutas
import Login from "../Vistas/Login/Login";
import Estadisticas from "../Vistas/Estadisticas/Estadisticas";
import Reservaciones from "../Vistas/Reservaciones/Reservaciones";
import Calendarios from "../Vistas/Calendarios/Calendarios";
import Cupones from "../Vistas/Cupones/Cupones";
import DetalleColeccionCupones from "../Vistas/Cupones/SubVistas/DetalleColeccion/DetalleColeccion";
import Usuarios from "../Vistas/Usuarios/Usuarios";
import Finanzas from "../Vistas/Finanzas/Finanzas";
import DesgloseMovimientos from "../Vistas/Finanzas/SubVistas/DesgloseMovimientos/DesgloseMovimientos";

//Animaciones
import * as AnimacionesGenerales from "../AnimacionesGenerales/AnimacionesGenerales_exporter";

const App = () => {
  const [visibilidadCargando, setVisibilidadCargando] = useState(false);
  const cargandoAuth = useSelector((state) => AuthSelector.cargandoAuth(state));
  const cargandoReservaciones = useSelector((state) =>
    ReservacionesSelector.cargandoReservaciones(state)
  );
  const cargandoUsuarios = useSelector((state) =>
    UsuariosSelector.cargandoUsuarios(state)
  );
  const cargandoFinanzas = useSelector((state) =>
    FinanzasSelector.cargandoFinanzas(state)
  );
  const cargandoCupones = useSelector((state) =>
    CuponesSelector.cargandoCupones(state)
  );
  useEffect(() => {
    if (visibilidadCargando) {
      AnimacionesGenerales.IniciarCargando();
    }
    if (
      cargandoAuth ||
      cargandoReservaciones ||
      cargandoUsuarios ||
      cargandoFinanzas ||
      cargandoCupones
    ) {
      setVisibilidadCargando(true);
    } else {
      AnimacionesGenerales.FinalizarCargando(() =>
        setVisibilidadCargando(false)
      );
    }
  }, [
    visibilidadCargando,
    cargandoAuth,
    cargandoReservaciones,
    cargandoUsuarios,
    cargandoFinanzas,
    cargandoCupones,
  ]);
  return (
    <div className="App">
      <Router>
        <ReduxListener />
        <BarraLateral />
        {visibilidadCargando && <Cargando />}
        <Suspense fallback={<Cargando />} className="router">
          <Switch>
            <Route exact path="/login" name="Login" component={Login} />
            {/* <Route exact path="/" name="Inicio" /> */}
            <PrivateRoute
              exact
              path={["/", "/estadisticas"]}
              name="Estadisticas"
              component={Estadisticas}
            />
            <PrivateRoute
              exact
              path={["/reservaciones", "/reservaciones/:codigoDeReservacion"]}
              name="Reservaciones"
              component={Reservaciones}
            />
            <PrivateRoute
              exact
              path="/calendarios"
              name="Calendarios"
              component={Calendarios}
            />
            <PrivateRoute
              exact
              path="/cupones"
              name="Cupones"
              component={Cupones}
            />
            <PrivateRoute
              exact
              path="/cupones/coleccion/:idColeccion"
              name="Coleccion de Cupones"
              component={DetalleColeccionCupones}
            />
            <PrivateRoute
              exact
              path="/usuarios"
              name="Usuarios"
              component={Usuarios}
            />
            <PrivateRoute
              exact
              path="/finanzas"
              name="Finanzas"
              component={Finanzas}
            />
            <PrivateRoute
              exact
              path="/finanzas/cuenta/:idCuenta"
              name="Desglose de movimientos"
              component={DesgloseMovimientos}
            />
            {/* <Redirect to="/" /> */}
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
