export default function (fecha) {
  let string = fecha.getDate() + " - ";
  switch (fecha.getMonth()) {
    case 0:
      string += "ene";
      break;
    case 1:
      string += "feb";
      break;
    case 2:
      string += "mar";
      break;
    case 3:
      string += "abr";
      break;
    case 4:
      string += "may";
      break;
    case 5:
      string += "jun";
      break;
    case 6:
      string += "jul";
      break;
    case 7:
      string += "ago";
      break;
    case 8:
      string += "sep";
      break;
    case 9:
      string += "oct";
      break;
    case 10:
      string += "nov";
      break;
    case 11:
      string += "dic";
      break;
    default:
      break;
  }
  return string;
}
