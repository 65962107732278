import React, { Fragment, useEffect, useState } from "react";
import "./Calendarios.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as ReservacionesActions from "../../redux/Actions/ReservacionesActions";
import * as ReservacionesSelectors from "../../redux/Selectors/ReservacionesSelector";

//Componentes
import VisualizadorDeReservacion from "../../Componentes/VisualizadorDeReservacion/VisualizadorDeReservacion";
import Modal from "../../Componentes/Modal/Modal";
import ConfiguradorDeFechas from "../../Componentes/ConfiguradorDeFechas/ConfiguradorDeFechas";
import VisualizadorSobrereserva from "../../Componentes/VisualizadorSobrereserva/VisualizadorSobrereserva";

//consts
import { datos as datosCabanas } from "../../consts/CaracteristicasCabanas";

//utils
import fechaMesYDiaCorto from "../../utils/fechaMesYDiaCorto";
import diaSemanaString from "../../utils/diaSemanaString";
import SQLtoJSDateParser from "../../utils/SQLtoJSDateParser";
import jsToSqlDate from "../../utils/NodeToSQLDateParser";
import fechaEntreRango from "../../utils/fechaEntreRango";
import arrayDeNochesDeViaje from "../../utils/arrayDeNochesDeViaje";

//Hooks
import useWindowSize from "../../Hooks/useWindowsSize";

//iconos
import * as AiIcons from "react-icons/ai";
import * as ImIcons from "react-icons/im";
import * as BsIcons from "react-icons/bs";
import * as SiIcons from "react-icons/si";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";
import { set } from "animejs";

const Calendario = () => {
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const reservaciones = useSelector((state) =>
    ReservacionesSelectors.reservaciones(state)
  );
  const refrescarReservaciones = useSelector((state) =>
    ReservacionesSelectors.refrescarReservaciones(state)
  );
  const fechasBloqueadas = useSelector((state) =>
    ReservacionesSelectors.fechasBloqueadas(state)
  );
  const tarifas = useSelector((state) => ReservacionesSelectors.tarifas(state));
  const minimoNochesExcepciones = useSelector((state) =>
    ReservacionesSelectors.minimoNochesExcepciones(state)
  );
  const [fechaEnfocada, setfechaEnfocada] = useState(new Date(Date.now()));
  const [reservacionSeleccionada, setReservacionSeleccionada] = useState(null);
  const [reservacionesSobrereserva, setReservacionesSobrereserva] = useState(
    []
  );
  const [modalVisualizadorReservacion, setModalVisualizadorReservacion] =
    useState(false);
  const [modalSobrereserva, setModalSobrereserva] = useState(false);
  const [seleccion1, setSeleccion1] = useState(null);
  const [seleccion2, setSeleccion2] = useState(null);
  const [seleccionDinamica, setSeleccionDinamica] = useState(null);
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState(null);
  const [configuracionFechas, setConfiguracionFechas] = useState(false);
  const [cantidadDeDiasVisibles, setCantidadDeDiasVisibles] = useState(7);
  const [bloqueosAirbnb, setBloqueosAirbnb] = useState([]);
  useEffect(() => {
    setCantidadDeDiasVisibles(Math.floor(width / 160));
  }, [width]);
  useEffect(() => {
    if (configuracionFechas) {
      AnimacionesGenerales.AbrirConfiguradorDeFechas();
    } else {
      AnimacionesGenerales.CerrarConfiguradorDeFechas();
    }
  }, [configuracionFechas]);
  useEffect(() => {
    dispatch(ReservacionesActions.getReservaciones());
  }, [dispatch]);
  useEffect(() => {
    if (refrescarReservaciones) {
      dispatch(ReservacionesActions.getReservaciones());
      setSeleccion1(null);
      setSeleccion2(null);
      setSeleccionDinamica(null);
      setFechasSeleccionadas(null);
      setConfiguracionFechas(false);
    }
  }, [refrescarReservaciones, dispatch]);
  useEffect(() => {
    if (seleccion1 && seleccion2) {
      let fecha1 = Date.parse(
        new Date(
          seleccion1.fecha.getFullYear(),
          seleccion1.fecha.getMonth(),
          seleccion1.fecha.getDate()
        )
      );
      let fecha2 = Date.parse(
        new Date(
          seleccion2.fecha.getFullYear(),
          seleccion2.fecha.getMonth(),
          seleccion2.fecha.getDate()
        )
      );
      let minFecha = Math.min(fecha1, fecha2);
      let maxFecha = Math.max(fecha1, fecha2);
      let minCab = Math.min(
        Number(seleccion1.cabana),
        Number(seleccion2.cabana)
      );
      let maxCab = Math.max(
        Number(seleccion1.cabana),
        Number(seleccion2.cabana)
      );
      let obj = {};
      for (
        let indexFecha = minFecha;
        indexFecha <= maxFecha;
        indexFecha += 86400000
      ) {
        let fecha = jsToSqlDate(new Date(indexFecha));
        obj[fecha] = {};
        for (let indexCabana = minCab; indexCabana <= maxCab; indexCabana++) {
          obj[fecha][`Cabana_${indexCabana}`] = true;
        }
      }
      setFechasSeleccionadas(obj);
      setConfiguracionFechas(true);
    } else {
      setFechasSeleccionadas(null);
      setConfiguracionFechas(false);
    }
  }, [seleccion1, seleccion2]);
  useEffect(() => {
    if (reservaciones && reservaciones.airbnb) {
      let objetoFechas = {};
      let array = [...reservaciones.airbnb];
      array = array.filter(
        (reservacion) => reservacion.Codigo_de_reservacion === null
      );
      array.forEach((reservacion) => {
        let arrayDeNoches = arrayDeNochesDeViaje(
          SQLtoJSDateParser(reservacion.Fecha_de_entrada),
          SQLtoJSDateParser(reservacion.Fecha_de_salida)
        );
        arrayDeNoches.forEach((noche) => {
          if (objetoFechas[jsToSqlDate(noche)]) {
            objetoFechas[jsToSqlDate(noche)][
              `Cabana_${reservacion.Cabana}`
            ] = true;
          } else {
            objetoFechas[jsToSqlDate(noche)] = {};
            objetoFechas[jsToSqlDate(noche)][
              `Cabana_${reservacion.Cabana}`
            ] = true;
          }
        });
      });
      setBloqueosAirbnb(objetoFechas);
    }
  }, [reservaciones]);

  const setSelecciones = (fecha, cabana) => {
    if (fecha && cabana) {
      if (seleccion1 === null && seleccion2 === null) {
        setSeleccion1({ fecha, cabana });
      } else if (seleccion1 !== null && seleccion2 !== null) {
        setSeleccion1({ fecha, cabana });
        setSeleccion2(null);
      } else if (seleccion1 !== null && seleccion2 === null) {
        setSeleccion2({ fecha, cabana });
      }
    } else {
      setSeleccion1(null);
      setSeleccion2(null);
    }
  };
  const visualizacionDeFechas = (longitud) => {
    if (tarifas) {
      let array = [];
      const determinacionDeTarifa = (fecha) => {
        let tarifasOrdenadas = [...tarifas].sort((registro1, registro2) => {
          if (registro1.Tarifa === "Alta") return -1;
          if (registro2.Tarifa === "Dias festivos") return 1;
          return 0;
        });
        let tarifa;
        tarifasOrdenadas.forEach((registro) => {
          let fechaInicio = SQLtoJSDateParser(registro.Fecha_de_inicio);
          let fechaFin = SQLtoJSDateParser(registro.Fecha_fin);
          if (fechaEntreRango(fecha, fechaInicio, fechaFin)) {
            tarifa = registro.Tarifa;
          }
        });
        return tarifa;
      };
      for (let index = 0; index < longitud; index++) {
        let fecha = new Date(Date.parse(fechaEnfocada) + 86400000 * index);
        array.push(
          <div
            key={index}
            className={"etiquetasFechas " + determinacionDeTarifa(fecha)}
          >
            <p>{determinacionDeTarifa(fecha)}</p>
            <p>{fechaMesYDiaCorto(fecha)}</p>
            <p>{diaSemanaString(fecha)}</p>
          </div>
        );
      }
      return (
        <Fragment>
          <div className="divBotonesVisualizacionFechas">
            <AiIcons.AiOutlineArrowLeft
              onClick={() =>
                setfechaEnfocada(new Date(Date.parse(fechaEnfocada) - 86400000))
              }
            />
            <input
              id="boton-calendario-vista-calendarios"
              type="date"
              onChange={(event) =>
                setfechaEnfocada(new Date(event.target.value))
              }
            />
            <AiIcons.AiOutlineArrowRight
              onClick={() =>
                setfechaEnfocada(new Date(Date.parse(fechaEnfocada) + 86400000))
              }
            />
          </div>

          <div className="visualizacionDeFechas">
            <div className="divVacio"></div>
            <div className="visualizacionDeFechas">{array}</div>
          </div>
        </Fragment>
      );
    }
  };
  const constructorDeDia = (fecha, idCabana, key) => {
    const reservacionDelDia = () => {
      if (reservaciones) {
        let array = [];
        for (const fuente in reservaciones) {
          if (fuente !== "archivosAdjuntos" && reservaciones[fuente]) {
            //FIX
            let reservacionesConFuente = reservaciones[fuente].map(
              (reservacion) => {
                reservacion.fuente = fuente;
                return reservacion;
              }
            );
            array = array.concat(reservacionesConFuente);
          }
        }
        //Excluye los bloqueos de airbnb
        array = array
          .filter((reservacion) => reservacion.fuente !== "airbnb")
          .concat(
            array.filter(
              (reservacion) =>
                reservacion.fuente === "airbnb" &&
                reservacion.Codigo_de_reservacion !== null
            )
          );
        //Excluye reservaciones canceladas y rechazadas
        return array.filter(
          (reservacion) =>
            reservacion.Cabana.split("-").includes(idCabana) &&
            reservacion.Estatus !== "Cancelada" &&
            reservacion.Estatus !== "Rechazada" &&
            Date.parse(SQLtoJSDateParser(reservacion.Fecha_de_entrada)) <=
              Date.parse(fecha) &&
            Date.parse(SQLtoJSDateParser(reservacion.Fecha_de_salida)) >
              Date.parse(fecha)
        );
      }
    };
    let reservacion = reservacionDelDia();
    const definicionDeClase = () => {
      let string = "diaDeCalendario";
      if (reservacion.length > 1) {
        string = string.concat(" fechaSobrereserva");
      }
      if (reservacion.length === 1) {
        string = string.concat(
          ` ${reservacion[0].fuente}Fuente${
            reservacion[0].Estatus
              ? reservacion[0].Estatus.replaceAll(" ", "")
              : ""
          }`
        );
        if (
          Date.parse(fecha) >=
            Date.parse(SQLtoJSDateParser(reservacion[0].Fecha_de_entrada)) &&
          Date.parse(fecha) <=
            Date.parse(SQLtoJSDateParser(reservacion[0].Fecha_de_entrada)) +
              86400000
        ) {
          string = string.concat(" fechaDeEntrada");
        }
        if (
          Date.parse(fecha) >=
            Date.parse(SQLtoJSDateParser(reservacion[0].Fecha_de_salida)) -
              86400000 &&
          Date.parse(fecha) <=
            Date.parse(SQLtoJSDateParser(reservacion[0].Fecha_de_salida))
        ) {
          string = string.concat(" fechaDeSalida");
        }
      }
      if (
        Date.parse(fecha) <
        Date.parse(
          new Date(
            new Date(Date.now()).getFullYear(),
            new Date(Date.now()).getMonth(),
            new Date(Date.now()).getDate()
          )
        )
      ) {
        string = string.concat(" fechaPasada");
      }
      if (seleccion1 && !seleccion2 && seleccionDinamica) {
        let minFecha = Math.min(
          Date.parse(seleccion1.fecha),
          Date.parse(seleccionDinamica.fecha)
        );
        let maxFecha = Math.max(
          Date.parse(seleccion1.fecha),
          Date.parse(seleccionDinamica.fecha)
        );
        let minCab = Math.min(
          Number(seleccion1.cabana),
          Number(seleccionDinamica.cabana)
        );
        let maxCab = Math.max(
          Number(seleccion1.cabana),
          Number(seleccionDinamica.cabana)
        );
        if (
          Date.parse(fecha) >= minFecha &&
          Date.parse(fecha) <= maxFecha &&
          Number(idCabana) >= minCab &&
          Number(idCabana) <= maxCab
        ) {
          string = string.concat(" fechaSeleccionDinamica");
        }
      }
      if (seleccion1 && seleccion2) {
        let minFecha = Math.min(
          Date.parse(seleccion1.fecha),
          Date.parse(seleccion2.fecha)
        );
        let maxFecha = Math.max(
          Date.parse(seleccion1.fecha),
          Date.parse(seleccion2.fecha)
        );
        let minCab = Math.min(
          Number(seleccion1.cabana),
          Number(seleccion2.cabana)
        );
        let maxCab = Math.max(
          Number(seleccion1.cabana),
          Number(seleccion2.cabana)
        );
        if (
          Date.parse(fecha) >= minFecha &&
          Date.parse(fecha) <= maxFecha &&
          Number(idCabana) >= minCab &&
          Number(idCabana) <= maxCab
        ) {
          string = string.concat(" fechaSeleccionada");
        }
      }
      return string;
    };
    const renderIndicadores = (fecha, idCabana) => {
      return (
        <Fragment>
          {fechasBloqueadas &&
          fechasBloqueadas[jsToSqlDate(fecha)] &&
          fechasBloqueadas[jsToSqlDate(fecha)][`Cabana_${idCabana}`] ? (
            <BsIcons.BsFillLockFill />
          ) : (
            ""
          )}
          {minimoNochesExcepciones &&
          minimoNochesExcepciones[jsToSqlDate(fecha)] &&
          minimoNochesExcepciones[jsToSqlDate(fecha)][`Cabana_${idCabana}`] ? (
            <span className="minimoDeNoches">
              <BsIcons.BsFillCalendarWeekFill />
              {
                minimoNochesExcepciones[jsToSqlDate(fecha)][
                  `Cabana_${idCabana}`
                ]
              }
            </span>
          ) : (
            ""
          )}
          {bloqueosAirbnb &&
          bloqueosAirbnb[jsToSqlDate(fecha)] &&
          bloqueosAirbnb[jsToSqlDate(fecha)][`Cabana_${idCabana}`] ? (
            <SiIcons.SiAirbnb />
          ) : (
            ""
          )}
        </Fragment>
      );
    };
    return {
      Codigo_de_reservacion: reservacion[0]
        ? reservacion[0].Codigo_de_reservacion
        : null,
      html: (
        <div key={key} className={definicionDeClase()}>
          <div
            className="background"
            onClick={() => setSelecciones(fecha, idCabana)}
            onMouseOver={() => {
              if (seleccion1) {
                setSeleccionDinamica({
                  fecha: fecha,
                  cabana: idCabana,
                });
              }
            }}
          ></div>
          <p
            onClick={() => {
              if (reservacion[0] && reservacion.length === 1) {
                if (reservacion[0].fuente === "directas") {
                  console.log(reservacion[0]);
                  setReservacionSeleccionada(reservacion[0]);
                  setModalVisualizadorReservacion(true);
                } else if (reservacion[0].fuente === "airbnb") {
                  let a = document.createElement("a");
                  a.href = reservacion[0]["URL"];
                  a.target = "_blank";
                  a.click();
                }
              } else if (reservacion.length > 1) {
                setReservacionesSobrereserva([...reservacion]);
                setModalSobrereserva(true);
              }
            }}
          >
            {reservacion[0] ? (
              reservacion.length > 1 ? (
                <span>
                  <ImIcons.ImWarning /> Sobrereserva
                </span>
              ) : (
                reservacion[0].Cliente || reservacion[0].Codigo_de_reservacion
              )
            ) : (
              ""
            )}
          </p>
          <div className="indicadores">
            {renderIndicadores(fecha, idCabana)}
          </div>
        </div>
      ),
      htmlSinTexto: (
        <div key={key} className={definicionDeClase()}>
          <div
            className="background"
            onClick={() => setSelecciones(fecha, idCabana)}
            onMouseOver={() => {
              if (seleccion1) {
                setSeleccionDinamica({
                  fecha: fecha,
                  cabana: idCabana,
                });
              }
            }}
          ></div>
          <p
            onClick={() => {
              if (reservacion[0] && reservacion.length === 1) {
                if (reservacion[0].fuente === "directas") {
                  setReservacionSeleccionada(reservacion[0]);
                  setModalVisualizadorReservacion(true);
                } else if (reservacion[0].fuente === "airbnb") {
                  let a = document.createElement("a");
                  a.href = reservacion[0]["URL"];
                  a.target = "_blank";
                  a.click();
                }
              } else if (reservacion.length > 1) {
                setReservacionesSobrereserva([...reservacion]);
                setModalSobrereserva(true);
              }
            }}
          >
            {reservacion[0]
              ? reservacion.length > 1 && (
                  <span>
                    <ImIcons.ImWarning /> Sobrereserva
                  </span>
                )
              : ""}
          </p>
          <div className="indicadores">
            {renderIndicadores(fecha, idCabana)}
          </div>
        </div>
      ),
    };
  };
  const constructorDeFilasDeDias = (longitud, idCabana) => {
    let array = [];
    for (let index = 0; index < longitud; index++) {
      let dia = constructorDeDia(
        new Date(Date.parse(fechaEnfocada) + 86400000 * index),
        idCabana,
        index
      );
      if (index === 0) {
        dia.conTexto = true;
      } else if (
        index > 0 &&
        dia.Codigo_de_reservacion !== array[index - 1].Codigo_de_reservacion
      ) {
        dia.conTexto = true;
      } else {
        dia.conTexto = false;
      }
      array.push(dia);
    }
    return (
      <div className="filaDeDias">
        {array.map((dia) => {
          if (dia.conTexto) {
            return dia.html;
          } else {
            return dia.htmlSinTexto;
          }
        })}
      </div>
    );
  };
  const constructorDeCalendarioDeCabana = (longitud) => {
    return Object.values(datosCabanas).map((cabana, key) => (
      <div key={key} className="calendarioCabana">
        <div className="divNombreCalendarioCabana">
          <p>{cabana.nombre}</p>
        </div>
        {constructorDeFilasDeDias(longitud, cabana.Id)}
      </div>
    ));
  };
  return (
    <div id="calendarios">
      <ConfiguradorDeFechas
        cerrar={() => {
          setSeleccion1(null);
          setSeleccion2(null);
          setConfiguracionFechas(false);
        }}
        fechasSeleccionadas={fechasSeleccionadas}
      />
      {visualizacionDeFechas(cantidadDeDiasVisibles)}
      {constructorDeCalendarioDeCabana(cantidadDeDiasVisibles)}
      <Modal
        visibilidad={modalVisualizadorReservacion && reservacionSeleccionada}
        setModal={setModalVisualizadorReservacion}
      >
        <VisualizadorDeReservacion
          cerrarModal={() => setModalVisualizadorReservacion(false)}
          reservacion={reservacionSeleccionada}
        />
      </Modal>
      <Modal
        visibilidad={modalSobrereserva && reservacionesSobrereserva.length > 1}
        setModal={setModalSobrereserva}
        forzado={true}
      >
        <VisualizadorSobrereserva
          reservaciones={reservacionesSobrereserva}
          funcionCerrar={() => {
            setModalSobrereserva(false);
            setReservacionesSobrereserva([]);
          }}
        />
      </Modal>
    </div>
  );
};

export default Calendario;
