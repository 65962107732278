import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as BootstrapIcons from "react-icons/bs";
import "./BarraLateral.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as AuthSelectors from "../../redux/Selectors/AuthSelector";
import * as AuthActions from "../../redux/Actions/AuthActions";

//Hooks
import useWindowsSize from "../../Hooks/useWindowsSize";

//Animaciones
import * as AnimacionesGenerales from "../../AnimacionesGenerales/AnimacionesGenerales_exporter";

//media
import imgHeaderBarraLateral from "../../media/imagenes/Logotipo sin slogan sin fondo.png";

const BarraLateral = () => {
  const dispatch = useDispatch();
  const [width, height] = useWindowsSize();
  const [desplegada, setDesplegada] = useState(false);
  const location = useLocation();
  const logged = useSelector((state) => AuthSelectors.logged(state));
  const userInfo = useSelector((state) => AuthSelectors.userInfo(state));
  useEffect(() => {
    if (desplegada) {
      AnimacionesGenerales.EntraBarraLateral();
    } else {
      AnimacionesGenerales.SaleBarraLateral();
    }
  }, [desplegada]);
  useEffect(() => {
    if (width > 950) {
      setDesplegada(true);
    } else {
      setDesplegada(false);
    }
  }, [width]);
  if (logged && userInfo) {
    return (
      <div className="barra-lateral">
        <div
          className="boton-desplegar-barra-lateral"
          onClick={() => setDesplegada(!desplegada)}
        >
          <BootstrapIcons.BsLayoutTextWindow />
        </div>
        <div className="header-barra-lateral">
          <img src={imgHeaderBarraLateral} alt="Logo Cima Calidez" />
          <p>Panel de Administrador</p>
          <button onClick={() => dispatch(AuthActions.logOut())}>
            Cerrar sesión
          </button>
        </div>
        {(userInfo.rol === "Administrador" ||
          userInfo.rol === "Coordinador") && (
          <Link
            to="/estadisticas"
            className={
              location.pathname === "/estadisticas"
                ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
                : "opciones-barra-lateral"
            }
            onClick={() => setDesplegada(false)}
          >
            <BootstrapIcons.BsGraphUp />
            <p>Estadísticas</p>
          </Link>
        )}
        {userInfo.rol === "Administrador" && (
          <Link
            to="/reservaciones"
            className={
              location.pathname === "/reservaciones"
                ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
                : "opciones-barra-lateral"
            }
            onClick={() => setDesplegada(false)}
          >
            <BootstrapIcons.BsLayoutTextWindow /> <p>Reservaciones</p>
          </Link>
        )}
        <Link
          to="/calendarios"
          className={
            location.pathname === "/calendarios"
              ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
              : "opciones-barra-lateral"
          }
          onClick={() => setDesplegada(false)}
        >
          <BootstrapIcons.BsFillCalendarRangeFill />
          <p>Calendarios</p>
        </Link>
        {userInfo.rol === "Administrador" && (
          <Link
            to="/cupones"
            className={
              location.pathname === "/cupones"
                ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
                : "opciones-barra-lateral"
            }
            onClick={() => setDesplegada(false)}
          >
            <BootstrapIcons.BsFillRecordBtnFill />
            <p>Cupones</p>
          </Link>
        )}
        {userInfo.rol === "Administrador" && (
          <Link
            to="/usuarios"
            className={
              location.pathname === "/usuarios"
                ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
                : "opciones-barra-lateral"
            }
            onClick={() => setDesplegada(false)}
          >
            <BootstrapIcons.BsFillPeopleFill />
            <p>Usuarios</p>
          </Link>
        )}
        <Link
          to="/aseo"
          className={
            location.pathname === "/aseo"
              ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
              : "opciones-barra-lateral"
          }
          onClick={() => setDesplegada(false)}
        >
          <BootstrapIcons.BsFillCheckCircleFill />
          <p>Tareas de Aseo</p>
        </Link>
        <Link
          to="/mantenimiento"
          className={
            location.pathname === "/mantenimiento"
              ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
              : "opciones-barra-lateral"
          }
          onClick={() => setDesplegada(false)}
        >
          <BootstrapIcons.BsHammer />
          <p>Mantenimiento</p>
        </Link>
        <Link
          to="/inventarios"
          className={
            location.pathname === "/inventarios"
              ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
              : "opciones-barra-lateral"
          }
          onClick={() => setDesplegada(false)}
        >
          <BootstrapIcons.BsServer />
          <p>Inventarios</p>
        </Link>
        {(userInfo.rol === "Administrador" ||
          userInfo.rol === "Coordinador") && (
          <Link
            to="/finanzas"
            className={
              location.pathname === "/finanzas"
                ? "opciones-barra-lateral-seleccionada opciones-barra-lateral"
                : "opciones-barra-lateral"
            }
            onClick={() => setDesplegada(false)}
          >
            <BootstrapIcons.BsClipboardData />
            <p>Finanzas</p>
          </Link>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default BarraLateral;
