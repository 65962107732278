import { put, call, takeLatest } from "redux-saga/effects";
import { actApiCall, apiCall } from "../ApiCalls";
import * as types from "../actionTypes";
import Swal from "sweetalert2";
import { getToken } from "../../services/tokenServices";

export function* getEstadisticasDemandaTodaSaga({ payload }) {
  try {
    const resultados = yield call(
      actApiCall,
      "GET",
      `estadisticas/demanda_todo`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_ESTADISTICAS_DEMANDA_TODA_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_ESTADISTICAS_DEMANDA_TODA_ERROR, payload });
  }
}

export function* getEstadisticasBusquedasSaga({ payload }) {
  try {
    const resultados = yield call(
      actApiCall,
      "GET",
      `estadisticas/busquedas`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_ESTADISTICAS_BUSQUEDAS_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_ESTADISTICAS_BUSQUEDAS_ERROR, payload });
  }
}

export function* getEstadisticasCotizacionesSaga({ payload }) {
  try {
    const resultados = yield call(
      actApiCall,
      "GET",
      `estadisticas/cotizaciones_cabanas`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_ESTADISTICAS_COTIZACIONES_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_ESTADISTICAS_COTIZACIONES_SUCCESS, payload });
  }
}

export function* getEstadisticasListoReservarSaga({ payload }) {
  try {
    const resultados = yield call(
      actApiCall,
      "GET",
      `estadisticas/listo_reservar`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_ESTADISTICAS_LISTO_RESERVAR_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({ type: types.GET_ESTADISTICAS_LISTO_RESERVAR_ERROR, payload });
  }
}

export function* getTop20FechasProximasMasBuscadasSaga({ payload }) {
  try {
    const resultados = yield call(
      actApiCall,
      "GET",
      `estadisticas/top_20_fechas_proximas_mas_buscadas`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({
      type: types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS_ERROR,
      payload,
    });
  }
}

export function* getConfirmacionesPorFechasSaga({ payload }) {
  try {
    const resultados = yield call(
      apiCall,
      "GET",
      `estadisticas/confirmaciones_por_fechas`,
      null,
      {
        "Content-Type": "application/json",
        "x-auth-token": getToken(),
      }
    );
    yield put({
      type: types.GET_CONFIRMACIONES_POR_FECHAS_SUCCESS,
      resultados,
    });
  } catch (error) {
    Swal.fire("Hubo un error!", error.response.data, "error");
    yield put({
      type: types.GET_CONFIRMACIONES_POR_FECHAS_ERROR,
      payload,
    });
  }
}

export default function* ComparadorSagas() {
  yield takeLatest(
    types.GET_ESTADISTICAS_DEMANDA_TODA,
    getEstadisticasDemandaTodaSaga
  );
  yield takeLatest(
    types.GET_ESTADISTICAS_BUSQUEDAS,
    getEstadisticasBusquedasSaga
  );
  yield takeLatest(
    types.GET_ESTADISTICAS_COTIZACIONES,
    getEstadisticasCotizacionesSaga
  );
  yield takeLatest(
    types.GET_ESTADISTICAS_LISTO_RESERVAR,
    getEstadisticasListoReservarSaga
  );
  yield takeLatest(
    types.GET_TOP_20_FECHAS_PROXIMAS_MAS_BUSCADAS,
    getTop20FechasProximasMasBuscadasSaga
  );
  yield takeLatest(
    types.GET_CONFIRMACIONES_POR_FECHAS,
    getConfirmacionesPorFechasSaga
  );
}
