import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import * as CuponesActions from "../../../../redux/Actions/CuponesActions";
import * as CuponesSelectors from "../../../../redux/Selectors/CuponesSelector";
import * as BootstrapIcons from "react-icons/bs";
import { BiChevronsLeft } from "react-icons/bi";
import { FcAddDatabase, FcAcceptDatabase } from "react-icons/fc";
import { AiOutlineSplitCells } from "react-icons/ai";
import Swal from "sweetalert2";
import "./DetalleColeccion.css";

//Componentes
import TablaDinamica from "../../../../Componentes/TablaDinamica/TablaDinamica";
import Modal from "../../../../Componentes/Modal/Modal";
import EditorDeCupon from "../../../../Componentes/EditorDeCupon/EditorDeCupon";
import LigarEmailACupon from "../../../../Componentes/LigarEmailACupon/LigarEmailACupon";
import ConfirmacionDeBorrado from "../../../../Componentes/ConfirmacionDeBorrado/ConfirmacionDeBorrado";

//utils
import binarioABoleano from "../../../../utils/binarioABoleano";

const DetalleColeccion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idColeccion } = useParams();
  const colecciones = useSelector((state) =>
    CuponesSelectors.colecciones(state)
  );
  const cupones = useSelector((state) => CuponesSelectors.cupones(state));
  const refrescar = useSelector((state) => CuponesSelectors.refrescar(state));
  const [nombre, setNombre] = useState(undefined);
  const [descripcion, setDescripcion] = useState(undefined);
  const [arrayCupones, setArrayCupones] = useState([]);
  const [cuponesDeColeccion, setCuponesDeColeccion] = useState([]);
  const [datosDeCupon, setDatosDeCupon] = useState(null);
  const [verModalNuevoCupon, setVerModalNuevoCupon] = useState(false);
  const [verModalActualizarCupon, setVerModalActualizarCupon] = useState(false);
  const [verModalBorrarCupon, setVerModalBorrarCupon] = useState(false);
  const [verModalBorrarColeccion, setVerModalBorrarColeccion] = useState(false);
  const [verModalActivarDesactivar, setVerModalActivarDesactivar] =
    useState(false);
  const [verModalLigarEmailACupon, setVerModalLigarEmailACupon] =
    useState(false);
  const [verModalAsociarCuponColeccion, setVerModalAsociarCuponColeccion] =
    useState(false);
  const [editarNombreDescripcion, setEditarNombreDescripcion] = useState(false);
  useEffect(() => {
    dispatch(CuponesActions.getCuponesYColecciones());
  }, [dispatch]);
  useEffect(() => {
    if (colecciones.length > 0 && idColeccion) {
      let obj = Object.assign(
        {},
        colecciones.find((obj) => obj._id === idColeccion)
      );
      if (obj.nombre) {
        setNombre(obj.nombre);
        setDescripcion(obj.descripcion);
        setArrayCupones(Array.isArray(obj.cupones) ? [...obj.cupones] : []);
      } else {
        Swal.fire("No existe esa coleccion", "", "error");
        history.push("/cupones");
      }
    }
  }, [colecciones, idColeccion, history]);
  useEffect(() => {
    if (arrayCupones.length > 0)
      setCuponesDeColeccion(
        cupones.filter((cupon) => arrayCupones.includes(cupon.Id))
      );
  }, [cupones, arrayCupones]);
  useEffect(() => {
    if (refrescar) {
      dispatch(CuponesActions.getCuponesYColecciones());
      setVerModalNuevoCupon(false);
      setVerModalActualizarCupon(false);
      setVerModalActivarDesactivar(false);
      setVerModalBorrarCupon(false);
      setVerModalLigarEmailACupon(false);
    }
  }, [refrescar, dispatch]);
  const cuponParser = ({
    Id,
    Tipo,
    Valor,
    Inicio,
    Fin,
    Creacion,
    Expiracion,
    Activo,
    email,
    Codigo_de_reservacion,
  }) => {
    return {
      Id,
      Tipo,
      Valor,
      Inicio,
      Fin,
      Creación: Creacion,
      Expiración: Expiracion,
      Activo: binarioABoleano(Activo) ? "Si" : "No",
      email,
      "Código de reservación": Codigo_de_reservacion,
    };
  };
  const constructorDeObjectoAccionesCupon = () => {
    if (cuponesDeColeccion) {
      let obj = {};
      cuponesDeColeccion.forEach((cupon) => {
        obj[cupon.Id] = (
          <Fragment>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() =>
                dispatch(
                  CuponesActions.putCupon({
                    IdCupon: cupon.Id,
                    coleccion: undefined,
                  })
                )
              }
            >
              <AiOutlineSplitCells />
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalActualizarCupon(true);
              }}
            >
              <BootstrapIcons.BsPencilSquare />
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalBorrarCupon(true);
              }}
            >
              <BootstrapIcons.BsTrashFill />
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalActivarDesactivar(true);
              }}
            >
              {binarioABoleano(cupon.Activo) ? (
                <BootstrapIcons.BsFillXCircleFill />
              ) : (
                <BootstrapIcons.BsCheckCircleFill />
              )}
            </button>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() => {
                setDatosDeCupon(cupon);
                setVerModalLigarEmailACupon(true);
              }}
            >
              <BootstrapIcons.BsFillEnvelopeFill />
            </button>
          </Fragment>
        );
      });
      return obj;
    }
  };
  const constructorDeObjectoAccionesCuponAsociable = () => {
    if (cupones) {
      let obj = {};
      cupones.forEach((cupon) => {
        obj[cupon.Id] = (
          <Fragment>
            <button
              className="botones-columna-acciones-cupones"
              onClick={() =>
                dispatch(
                  CuponesActions.putCupon({
                    IdCupon: cupon.Id,
                    coleccion: nombre,
                  })
                )
              }
            >
              <BootstrapIcons.BsPlusCircle />
            </button>
          </Fragment>
        );
      });
      return obj;
    }
  };
  return (
    <div className="router panel-cupones-detalle-coleccion">
      <div className="regresar" onClick={() => history.push("/cupones")}>
        <BiChevronsLeft />
        <p>Regresar a Cupones</p>
      </div>
      <div className="div-info-coleccion">
        <div className="div-botones-editar-coleccion">
          {editarNombreDescripcion ? (
            <Fragment>
              <button
                onClick={() => {
                  setEditarNombreDescripcion(false);
                  dispatch(
                    CuponesActions.putColeccionCupones({
                      _id: idColeccion,
                      nombre,
                      descripcion,
                    })
                  );
                }}
              >
                Guardar
              </button>
              <button onClick={() => setEditarNombreDescripcion(false)}>
                Cancelar
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button onClick={() => setEditarNombreDescripcion(true)}>
                Editar nombre y descripción
              </button>
              <button onClick={() => setVerModalBorrarColeccion(true)}>
                Borrar
              </button>
            </Fragment>
          )}
        </div>

        <div className="div-input-nombre-coleccion">
          <label>Nombre</label>
          <input
            disabled={!editarNombreDescripcion}
            defaultValue={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </div>
        <div className="div-input-descripcion-coleccion">
          <label>Descripción</label>
          <textarea
            disabled={!editarNombreDescripcion}
            defaultValue={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </div>
      </div>
      <div className="div-cupones-coleccion">
        {arrayCupones.length > 0 ? (
          <div className="div-tabla-cupones-asociados">
            <TablaDinamica
              datos={cuponesDeColeccion.map((cupon) => cuponParser(cupon))}
              mostrarColumnaID={true}
              puedeCrear={true}
              tieneBuscador={true}
              funcionCrear={() => setVerModalNuevoCupon(true)}
              acciones={constructorDeObjectoAccionesCupon()}
            />
          </div>
        ) : (
          <div className="div-sin-cupones">
            <p>No hay cupones asociados a esta coleccion</p>
            <div className="div-botones-sin-cupones">
              <button onClick={() => setVerModalNuevoCupon(true)}>
                <FcAddDatabase />
                <p>Crear cupón</p>
              </button>
              <button onClick={() => setVerModalAsociarCuponColeccion(true)}>
                <FcAcceptDatabase />
                <p>Asociar cupón</p>
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal visibilidad={verModalNuevoCupon} setModal={setVerModalNuevoCupon}>
        <EditorDeCupon
          crearOActualizar={"crear"}
          accionCancelar={() => setVerModalNuevoCupon(false)}
        />
      </Modal>
      <Modal
        visibilidad={verModalActualizarCupon}
        setModal={setVerModalActualizarCupon}
      >
        <EditorDeCupon
          crearOActualizar={"actualizar"}
          accionCancelar={() => setVerModalActualizarCupon(false)}
          datosDeCupon={datosDeCupon}
        />
      </Modal>
      <Modal
        visibilidad={verModalBorrarCupon}
        setModal={setVerModalBorrarCupon}
      >
        <ConfirmacionDeBorrado
          cerrarModal={() => setVerModalBorrarCupon(false)}
          mensaje={datosDeCupon && `¿Desea borrar el cupón ${datosDeCupon.Id}?`}
          funcionDeConfirmacion={() => {
            dispatch(CuponesActions.deleteCupon({ IdCupon: datosDeCupon.Id }));
          }}
        />
      </Modal>
      <Modal
        visibilidad={verModalBorrarColeccion}
        setModal={setVerModalBorrarColeccion}
      >
        <ConfirmacionDeBorrado
          cerrarModal={() => setVerModalBorrarColeccion(false)}
          mensaje={nombre && `¿Desea borrar el la colección ${nombre}?`}
          funcionDeConfirmacion={() => {
            dispatch(
              CuponesActions.deleteColeccionCupones({
                _id: idColeccion,
                coleccion: nombre,
              })
            );
            history.push("/cupones")
          }}
        />
      </Modal>
      <Modal
        visibilidad={verModalActivarDesactivar}
        setModal={setVerModalActivarDesactivar}
      >
        <ConfirmacionDeBorrado
          cerrarModal={() => setVerModalActivarDesactivar(false)}
          mensaje={
            datosDeCupon &&
            `¿Desea ${
              binarioABoleano(datosDeCupon.Activo) ? "desactivar" : "activar"
            } el cupón ${datosDeCupon.Id}?`
          }
          funcionDeConfirmacion={() => {
            dispatch(
              CuponesActions.activarDesactivarCupon({
                IdCupon: datosDeCupon.Id,
              })
            );
          }}
        />
      </Modal>
      <Modal
        visibilidad={verModalLigarEmailACupon}
        setModal={setVerModalLigarEmailACupon}
      >
        <LigarEmailACupon
          funcionCancelar={() => setVerModalLigarEmailACupon(false)}
          cupon={datosDeCupon}
        />
      </Modal>
      <Modal
        visibilidad={verModalAsociarCuponColeccion}
        setModal={setVerModalAsociarCuponColeccion}
        forzado={true}
      >
        <div className="div-asociar-cupon">
          <h2>Seleccione cupones para asociar</h2>
          <div className="div-tabla">
            <TablaDinamica
              datos={
                cupones &&
                cupones
                  .filter((cupon) => !cupon.Coleccion)
                  .map((cupon) => cuponParser(cupon))
              }
              mostrarColumnaID={true}
              tieneBuscador={true}
              acciones={constructorDeObjectoAccionesCuponAsociable()}
            />
          </div>
          <button
            className="boton-cancelar-asociar-cupon"
            onClick={() => setVerModalAsociarCuponColeccion(false)}
          >
            Cerrar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DetalleColeccion;
