import { all } from "redux-saga/effects";
import AuthSaga from "./AuthSaga";
import ReservacionesSaga from "./ReservacionesSaga";
import UsuariosSaga from "./UsuariosSaga";
import FinanzasSaga from "./FinanzasSaga";
import CuponesSaga from "./CuponesSaga";
import EstadisticasSaga from "./EstadisticasSaga";

/**
 * Redux Sagas sit between the Actions and Reducers listening for "messages"
 */

export default function* rootSaga(params) {
  console.log(" <---------  Sagas index --------->");
  // console.log(params)
  yield all([
    AuthSaga(),
    ReservacionesSaga(),
    UsuariosSaga(),
    FinanzasSaga(),
    CuponesSaga(),
    EstadisticasSaga(),
  ]);
}
