import React from "react";

const LogoContorno = () => {
  return (
    <svg
      version="1.1"
      id="LogoContorno"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="218.157px"
      height="175.75px"
      viewBox="0 0 218.157 170.75"
      enableBackground="new 0 0 218.157 170.75"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            fill="#ffffff00"
            stroke="#472101"
            strokeWidth="4"
            d="M103.04,6.076C52.84,60.076,20.474,114.27,0,158.093c4.374,1.589,8.956,3.012,13.703,4.27
			c18.324-38.281,46.696-84.853,89.834-131.246c11.678-13.335,20.977,0.995,30.168,10.227c34.12,35.959,56.576,81.923,70.59,122.678
			c4.215-0.581,9.205-1.286,13.861-2.619c-15.533-47.202-41.237-101.665-81.273-143.835C126.575,7.203,116.127-8.877,103.04,6.076"
          />
          <path
            fill="#ffffff00"
            stroke="#705A00"
            strokeWidth="4"
            d="M103.537,53.201c-35.979,38.708-61.684,77.521-79.864,111.552c4.326,0.927,8.741,1.742,13.226,2.461
			c16.86-27.951,38.633-58.14,66.638-88.267c11.678-13.335,20.977,0.995,30.168,10.233c21.835,23.01,38.902,50.117,52.018,77.328
			c4.574-0.538,8.223-1.009,10.751-1.367c-14.082-34.992-34.39-71.807-62.769-101.706C124.514,54.209,115.215,39.879,103.537,53.201
			"
          />
          <path
            fill="#ffffff00"
            stroke="#0D280B"
            strokeWidth="4"
            d="M102.106,99.386c-17.55,23.493-32.047,47.029-44.001,69.514c5.493,0.801,11.069,1.409,16.687,1.851
			c8.161-13.308,17.233-26.809,27.314-40.311c9.376-13.323,16.853,1.008,24.24,10.24c6.875,8.997,13.148,18.637,18.885,28.648
			c5.459-0.546,10.447-1.132,14.842-1.713c-9.203-20.536-20.355-40.451-33.727-57.988
			C118.959,100.395,111.483,86.064,102.106,99.386"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoContorno;
