import React, { Fragment, useEffect, useState } from "react";
import "./EditorDeReservacion.css";

//Redux
import { useDispatch, useSelector } from "react-redux";
import * as UsuariosActions from "../../redux/Actions/UsuariosActions";
import * as UsuariosSelectors from "../../redux/Selectors/UsuariosSelector";
import * as ReservacionesActions from "../../redux/Actions/ReservacionesActions";
import * as ReservacionesSelectors from "../../redux/Selectors/ReservacionesSelector";

//Utils
import fechaLargaConHorasMinutos from "../../utils/fechaLargaConHorasMinutos";
import SQLtoJSDateParser from "../../utils/SQLtoJSDateParser";
import binarioABoleano from "../../utils/binarioABoleano";

//Componentes
import Modal from "../Modal/Modal";

//Media
import * as GeneralSVG from "../../media/SVG/ExportadorGeneralSVG";
import * as BootstrapIcons from "react-icons/bs";

const EditorDeReservacion = ({ reservacion, cerrarModal }) => {
  const dispatch = useDispatch();
  const usuarioEnfocado = useSelector((state) =>
    UsuariosSelectors.usuarioEnfocado(state)
  );
  const nuevoCosto = useSelector((state) =>
    ReservacionesSelectors.costoTotal(state)
  );
  const [modalNuevoCosto, setModalNuevoCosto] = useState(false);
  const [modalNotificarCliente, setModalNotificarCliente] = useState(false);
  const [costoTotal, setCostoTotal] = useState(reservacion.Costo_total);
  const [anticipo, setAnticipo] = useState(
    reservacion.Anticipo ? reservacion.Anticipo : 0
  );
  const [cabana, setCabana] = useState(reservacion.Cabana.split("-"));
  const [fechaDeEntrada, setFechaDeEntrada] = useState(
    SQLtoJSDateParser(reservacion.Fecha_de_entrada)
  );
  const [fechaDeSalida, setFechaDeSalida] = useState(
    SQLtoJSDateParser(reservacion.Fecha_de_salida)
  );
  const [numeroDeAdultos, setNumeroDeAdultos] = useState(reservacion.Adultos);
  const [numeroDeNinos, setNumeroDeNinos] = useState(reservacion.Ninos);
  const [numeroDeBebes, setNumeroDeBebes] = useState(reservacion.Bebes);
  const [numeroDeMascotas, setNumeroDeMascotas] = useState(
    reservacion.Mascotas
  );
  const [nombre, setNombre] = useState(reservacion.Cliente);
  const [correoElectronico, setCorreoElectronico] = useState(reservacion.email);
  const [telefono, setTelefono] = useState(reservacion.Telefono);
  const checkboxHandler = (event, valor) => {
    if (event.target.checked && !cabana.includes(valor)) {
      let array = [...cabana];
      array.push(valor);
      setCabana(array);
    } else if (!event.target.checked && cabana.includes(valor)) {
      let array = [...cabana];
      array.splice(array.indexOf(valor), 1);
      setCabana(array);
    }
  };
  useEffect(() => {
    if (usuarioEnfocado) {
      dispatch(UsuariosActions.borrarUsuarioEnfocado());
    }
    if (
      reservacion.Usuario_registrado &&
      binarioABoleano(reservacion.Usuario_registrado)
    ) {
      dispatch(UsuariosActions.getInfoUsuario(reservacion.ID_usuario));
    }
  }, [reservacion, dispatch]);
  const actualizarReservacion = (notificar) => {
    dispatch(
      ReservacionesActions.putReservaciones({
        codigoDeReservacion: reservacion.Codigo_de_reservacion,
        cabana: cabana.join("-"),
        fechaDeEntrada,
        fechaDeSalida,
        numeroDeAdultos,
        numeroDeNinos,
        numeroDeBebes,
        numeroDeMascotas,
        nombre,
        correoElectronico,
        telefono,
        costoTotal,
        anticipo,
        notificarActualizacion: notificar,
      })
    );
  };
  const defineSiContinuaOActualiza = (continuacion) => {
    if (
      reservacion.Estatus === "Confirmada" ||
      reservacion.Estatus === "En proceso de confirmacion" ||
      reservacion.Estatus === "Pago pendiente"
    ) {
      continuacion();
    } else {
      actualizarReservacion(false);
    }
  };
  useEffect(() => {
    if (nuevoCosto !== null && nuevoCosto !== costoTotal) {
      defineSiContinuaOActualiza(() => setModalNuevoCosto(true));
    } else if (nuevoCosto === costoTotal) {
      defineSiContinuaOActualiza(() => setModalNotificarCliente(true));
    } else if (nuevoCosto === null) {
      setModalNuevoCosto(false);
    }
  }, [nuevoCosto, costoTotal]);
  return (
    <form
      id="editorDeReservacion"
      onSubmit={(event) => {
        event.preventDefault();
        let objetoParaNuevoCosto = {
          cabana: cabana.join("-"),
          fechaDeEntrada,
          fechaDeSalida,
          numeroDeAdultos,
          numeroDeMascotas,
        };
        dispatch(ReservacionesActions.getCosto(objetoParaNuevoCosto));
      }}
    >
      {reservacion && (
        <Fragment>
          <div
            className={
              reservacion.Estatus === "Confirmada"
                ? "encabezado confirmada"
                : reservacion.Estatus === "Cancelada"
                ? "encabezado cancelada"
                : "encabezado"
            }
          >
            <p className="titulo">
              {reservacion.Codigo_de_reservacion} -{" "}
              <select
                onChange={(event) =>
                  dispatch(
                    ReservacionesActions.cambiarEstatus({
                      codigoDeReservacion: reservacion.Codigo_de_reservacion,
                      estatus: event.target.value,
                    })
                  )
                }
                defaultValue={reservacion.Estatus}
              >
                <option>Confirmada</option>
                <option>Pago pendiente</option>
                <option>Cancelada</option>
              </select>
            </p>
            <p className="fecha">
              Fecha de creación:{" "}
              {fechaLargaConHorasMinutos(
                new Date(reservacion.Fecha_de_confirmacion)
              )}
            </p>
          </div>
          <div className="datosCliente bloque">
            <h3>Datos del cliente</h3>
            <p>
              Nombre:{" "}
              <span>
                <input
                  required
                  className="inputLargo"
                  type="text"
                  defaultValue={reservacion.Cliente}
                  onChange={(event) => setNombre(event.target.value)}
                />
              </span>
            </p>
            <p>
              Teléfono:{" "}
              <span>
                <input
                  required
                  className="inputLargo"
                  type="tel"
                  defaultValue={reservacion.Telefono}
                  onChange={(event) => setTelefono(event.target.value)}
                />
              </span>
            </p>
            <p>
              Email:{" "}
              <span>
                <input
                  required
                  className="inputLargo"
                  type="email"
                  defaultValue={reservacion.email}
                  onChange={(event) => setCorreoElectronico(event.target.value)}
                />
              </span>
            </p>
          </div>
          <div className="datosReservacion bloque">
            <h3>Datos de la reservación</h3>
            <p>
              Cabañas{" "}
              <input
                type="checkbox"
                defaultChecked={reservacion.Cabana.split("-").includes("1")}
                onChange={(event) => checkboxHandler(event, "1")}
              />
              <label>Ave azul</label>{" "}
              <input
                type="checkbox"
                defaultChecked={reservacion.Cabana.split("-").includes("2")}
                onChange={(event) => checkboxHandler(event, "2")}
              />
              <label>Jilguero</label>{" "}
              <input
                type="checkbox"
                defaultChecked={reservacion.Cabana.split("-").includes("3")}
                onChange={(event) => checkboxHandler(event, "3")}
              />
              <label>Tigrillo</label>{" "}
              <input
                type="checkbox"
                defaultChecked={reservacion.Cabana.split("-").includes("4")}
                onChange={(event) => checkboxHandler(event, "4")}
              />
              <label>Colibrí</label>{" "}
              <input
                type="checkbox"
                defaultChecked={reservacion.Cabana.split("-").includes("5")}
                onChange={(event) => checkboxHandler(event, "5")}
              />
              <label>Coa</label>
              <input
                type="checkbox"
                defaultChecked={reservacion.Cabana.split("-").includes("6")}
                onChange={(event) => checkboxHandler(event, "6")}
              />
              <label>Buho</label>
            </p>
            <p>
              Entrada:{" "}
              <span>
                <input
                  required
                  className="inputLargo"
                  type="date"
                  defaultValue={reservacion.Fecha_de_entrada}
                  onChange={(event) =>
                    setFechaDeEntrada(SQLtoJSDateParser(event.target.value))
                  }
                />
              </span>
            </p>
            <p>
              Salida:{" "}
              <span>
                <input
                  required
                  className="inputLargo"
                  type="date"
                  defaultValue={reservacion.Fecha_de_salida}
                  onChange={(event) =>
                    setFechaDeSalida(SQLtoJSDateParser(event.target.value))
                  }
                />
              </span>
            </p>
            <p>
              <span>
                <input
                  required
                  className="inputCorto"
                  type="number"
                  defaultValue={reservacion.Adultos}
                  onChange={(event) =>
                    setNumeroDeAdultos(Number(event.target.value))
                  }
                />
              </span>
              Adultos
            </p>
            <p>
              <span>
                <input
                  required
                  className="inputCorto"
                  type="number"
                  defaultValue={reservacion.Ninos}
                  onChange={(event) =>
                    setNumeroDeNinos(Number(event.target.value))
                  }
                />
              </span>
              Menores de 10 años
            </p>
            <p>
              <span>
                <input
                  required
                  className="inputCorto"
                  type="number"
                  defaultValue={reservacion.Bebes}
                  onChange={(event) =>
                    setNumeroDeBebes(Number(event.target.value))
                  }
                />
              </span>
              Menores de 3 años
            </p>
            <p>
              <span>
                <input
                  required
                  className="inputCorto"
                  type="number"
                  defaultValue={reservacion.Mascotas}
                  onChange={(event) =>
                    setNumeroDeMascotas(Number(event.target.value))
                  }
                />
              </span>
              Mascotas
            </p>
          </div>
          <div className="datosBalance bloque">
            <h3>Balance</h3>
            <p>Costo Total</p>
            <input
              type="number"
              className="inputLargo"
              value={costoTotal}
              onChange={(event) => setCostoTotal(event.target.value)}
            />
            <p>Anticipo</p>
            <input
              type="number"
              className="inputLargo"
              value={anticipo}
              onChange={(event) => setAnticipo(event.target.value)}
            />
            <p>Pago pendiente: ${costoTotal - anticipo}</p>
          </div>
        </Fragment>
      )}
      {usuarioEnfocado && (
        <div className="usuario">
          <h3>Usuario que reservó</h3>
          <img
            alt="imagen de perfil"
            src={
              usuarioEnfocado.imagenPerfil
                ? usuarioEnfocado.imagenPerfil
                : GeneralSVG.Avatar_predeterminado
            }
          />
          <p>
            {usuarioEnfocado.nombre} {usuarioEnfocado.apellido}
          </p>
          <p>{usuarioEnfocado.email}</p>
          <p>{usuarioEnfocado.isAdmin ? "Administrador" : "Cliente"}</p>
        </div>
      )}
      <div className="divBotones">
        <button
          onClick={(e) => {
            e.preventDefault();
            if (usuarioEnfocado) {
              dispatch(UsuariosActions.borrarUsuarioEnfocado());
            }
            cerrarModal();
          }}
        >
          Cerrar
        </button>
        <button type="submit">Actualizar</button>
        {reservacion.Estatus === "Pago pendiente" &&
          (reservacion.Tipo_de_pago === "ticket" ||
            reservacion.Tipo_de_pago === "atm") && (
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  ReservacionesActions.cancelarReservacionYOrdenDePago({
                    codigoDeReservacion: reservacion.Codigo_de_reservacion,
                  })
                );
              }}
            >
              Cancelar reservación y orden de pago
            </button>
          )}
      </div>
      <Modal
        visibilidad={modalNuevoCosto}
        forzado
        setModal={setModalNuevoCosto}
      >
        <div className="modalNuevoCosto">
          <h2>¿Actualizar costo según tarifas?</h2>
          <p>
            ${reservacion.Costo_total} <BootstrapIcons.BsArrowRightShort /> $
            {nuevoCosto}
          </p>
          <button
            className="cancelar"
            onClick={(e) => {
              e.preventDefault();
              setModalNuevoCosto(false);
              dispatch(ReservacionesActions.resetCosto());
            }}
          >
            Cancelar
          </button>
          <button
            className="ok"
            onClick={(e) => {
              e.preventDefault();
              setModalNuevoCosto(false);
              defineSiContinuaOActualiza(() => setModalNotificarCliente(true));
              dispatch(ReservacionesActions.resetCosto());
            }}
          >
            No
          </button>
          <button
            className="ok"
            onClick={(event) => {
              event.preventDefault();
              setCostoTotal(nuevoCosto);
              setModalNuevoCosto(false);
              defineSiContinuaOActualiza(() => setModalNotificarCliente(true));
            }}
          >
            Si
          </button>
        </div>
      </Modal>
      <Modal
        visibilidad={modalNotificarCliente}
        forzado
        setModal={setModalNotificarCliente}
      >
        <div className="modalNuevoCosto">
          <h2>¿Desea notificar los cambios al cliente?</h2>
          <button
            className="cancelar"
            onClick={(e) => {
              e.preventDefault();
              setModalNotificarCliente(false);
              dispatch(ReservacionesActions.resetCosto());
            }}
          >
            Cancelar
          </button>
          <button
            className="ok"
            onClick={(e) => {
              e.preventDefault();
              actualizarReservacion(false);
              setModalNotificarCliente(false);
              dispatch(ReservacionesActions.resetCosto());
            }}
          >
            No
          </button>
          <button
            className="ok"
            onClick={(event) => {
              event.preventDefault();
              actualizarReservacion(true);
              setModalNotificarCliente(false);
              dispatch(ReservacionesActions.resetCosto());
            }}
          >
            Si
          </button>
        </div>
      </Modal>
    </form>
  );
};

export default EditorDeReservacion;
